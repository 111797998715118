import React from "react";

function GravatarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M16 0a3.198 3.198 0 00-3.197 3.197v11.204a3.198 3.198 0 006.394 0V6.948A9.598 9.598 0 0125.598 16c0 5.297-4.301 9.599-9.599 9.599S6.4 21.303 6.4 16a9.552 9.552 0 012.812-6.787 3.204 3.204 0 00-4.531-4.531A15.987 15.987 0 00-.002 15.999c0 8.839 7.161 16 16 16s16-7.161 16-16-7.161-16-16-16z"></path>
    </svg>
  );
}

export default GravatarIcon;
