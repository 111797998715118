import React from "react";

function EvernoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-6.5 0 32 32"
    >
      <path d="M2.031 9.625h2.156a.894.894 0 00.875-.875s-.031-.438-.031-.969V6.406c0-.406.094-.75.281-1 .25-.375.719-.625 1.156-.75.5-.156 2.563-.281 3.906.25.5.188.969.844 1.063 1.469.781-.031 2.031-.031 3.125.094 1.375.156 2.438.344 2.969.531.5.156 1.063.625 1.219 1.531.281 1.563.656 7.813.469 9.906-.313 3.25-.781 5.219-1 5.844-1.031 3.219-1.938 3.344-4.469 3.344-3.188 0-4.188-.5-4.188-3.094 0-2.844 1.344-2.906 3.625-2.844.313.031-.031.344-.031.906 0 .531.219.75-.094.75-.625 0-1.531-.094-1.531.813 0 1.094.531 1.063 1.906 1.063 1.719 0 2.063-.25 2.063-1.813 0-2.563-.719-2.938-1.75-3.063-1.094-.125-2.188-.281-2.719-.469-1.313-.469-1.219-2.25-1.219-2.719 0-.094-.125-.063-.125 0 0 .719-.031 1.656-.406 2.781-.094.313-.188.5-.188.5-.406.906-1.125.563-2.25.438-1.125-.156-3.469-.625-4.531-1.063-.438-.219-.625-.438-.875-.938C.843 17.592.187 13.404.093 12.81c-.094-.781-.094-1.125-.094-1.125 0-.5.063-.969.344-1.375.125-.188.344-.375.625-.5.25-.094.625-.188 1.063-.188zM4.094 9H1.906c-.5 0-.969.094-1.344.25 0 .031-.125.094-.219.125l.031-.031 4.188-4.156h.031l-.063.125c-.156.281-.25.688-.25 1.094 0 .531.031 2.344.031 2.344 0 .125-.094.25-.219.25zm10.281 6.438c.563 0 1.125.156 1.531.344 0-.625-.125-1.656-1.156-1.688-.938-.031-1.219.75-1.281 1.375.281-.031.594-.063.906-.031z"></path>
    </svg>
  );
}

export default EvernoteIcon;
