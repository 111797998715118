import React from "react";

function VkontakteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 24 24"
    >
      <path d="M23.456 5.784a8.605 8.605 0 01-1.09 2.259l.019-.03q-.672 1.12-1.605 2.588-.8 1.159-.847 1.2a1.28 1.28 0 00-.267.618l-.001.007a.897.897 0 00.268.535l.4.446q3.21 3.299 3.611 4.548a.89.89 0 01-.112.829l.002-.003a.965.965 0 01-.784.289h.004H20.418c-.337 0-.647-.118-.89-.314l.003.002a6.928 6.928 0 01-.951-.948l-.009-.012q-.691-.781-1.226-1.315-1.782-1.694-2.63-1.694a.788.788 0 00-.516.135l.003-.002a.767.767 0 00-.16.584v-.004a12.532 12.532 0 00-.038 1.403v-.017 1.159a.78.78 0 01-.266.757l-.001.001a3.179 3.179 0 01-1.617.267l.013.001a8.323 8.323 0 01-4.275-1.268l.035.02A11.931 11.931 0 014.176 14.3l-.027-.042a26.36 26.36 0 01-2.471-3.992l-.07-.154A24.657 24.657 0 01.375 7.31l-.06-.185a6.646 6.646 0 01-.31-1.535l-.002-.025q0-.758.892-.758h2.63a1.058 1.058 0 01.739.225l-.002-.002c.2.219.348.488.421.788l.003.012a25.422 25.422 0 001.587 3.615l-.067-.137a14.56 14.56 0 001.623 2.576l-.023-.031q.8.982 1.248.982l.032.001a.4.4 0 00.347-.2l.001-.002a1.783 1.783 0 00.111-.787v.006-3.879a3.211 3.211 0 00-.32-1.267l.008.019a2.956 2.956 0 00-.45-.695l.003.004a1.099 1.099 0 01-.311-.619l-.001-.006c0-.17.078-.323.2-.423l.001-.001a.678.678 0 01.46-.178h4.154a.634.634 0 01.559.222l.001.001a1.36 1.36 0 01.159.763v-.005 5.173a.993.993 0 00.136.584l-.002-.004a.401.401 0 00.333.178h.001a.946.946 0 00.471-.162l-.003.002c.272-.187.506-.4.709-.641l.004-.005a15.606 15.606 0 001.655-2.25l.039-.07c.344-.57.716-1.272 1.053-1.993l.062-.147.446-.892a1.122 1.122 0 011.117-.759h-.003 2.631q1.066 0 .8.981z"></path>
    </svg>
  );
}

export default VkontakteIcon;
