import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import { toPng } from 'html-to-image';

import ReactGA from "react-ga";

import LogoBlue from "../../media/logo_blue.svg";

function Users(props) {

  const navigate = useNavigate();

  const [usersLoading, setUsersLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [usersError, setUsersError] = useState("");

  const [showForm, setShowForm] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [type, setType] = useState("admin"); // admin - user

  const toggleForm = (idVal, nameVal, emailVal, showPasswordVal, passwordVal, typeVal, formShow ) => {
    setShowForm(formShow);

    setId(idVal)
    setName(nameVal);
    setEmail(emailVal);
    setPassword(passwordVal);
    setShowPassword(showPasswordVal);
    setType(typeVal);
  }

  useEffect(() => {

      if (props.user.type == "user") {
        navigate("/");
      }

      ReactGA.pageview(window.location.pathname);

      try {
      
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev//marketplace/sandbox/users/neo`,
            {
              requestInfo: {type: "list"}
            }
          )
            .then(res => {
              if (res.data.err){
                setUsersError(res.data.err);
              } else if (res.data) {
                setUsersList(res.data.users);
                setUsersError("");   
              } 
              setUsersLoading(false);
            })
    
        } catch (error) {
          setUsersError("An error occurred, please try again later");
          setUsersLoading(false);
        }
        
    }, []);

    const [usernameOpen, setUsernameOpen] = useState(false);
    const hideUsernameDropdown = (e) => {
      if (!(e.target.closest('.username'))) {
        if (!(e.target.closest('.username_dropdown'))) {
          setUsernameOpen(false)
        }
      }
    }

    const handleInsert = () => {
      const userInfo = {
        type: "insert",
        Email: email,
        Password: password,
        Nickname: name,
        Type: type
      };
    
      axios.post('https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/sandbox/users/neo', { requestInfo: userInfo })
        .then(res => {
          if (res.data.err) {
            console.log('Error: ' + res.data.err);
          } else {
            setUsersList([...usersList, res.data.insertedUser])
            toggleForm("", "", "", true, "", "user", false)
          }
        })
        .catch(err => {
          console.log('An error occurred: ' + err.message);
        });
    };

    const handleUpdate = () => {
      const userInfo = {
        type: "update",
        CredentialId: id,
        Email: email,
        Nickname: name,
        Type: type
      };

      console.log(userInfo)
    
      axios.post('https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/sandbox/users/neo', { requestInfo: userInfo })
        .then(res => {
          if (res.data.err) {
            console.log('Error: ' + res.data.err);
          } else {
            setUsersList(usersList.map(us => {
              if (us.CredentialId == id) {
                return res.data.updatedUser
              } else {
                return us
              }
            }))
            toggleForm("", "", "", true, "", "user", false)
          }
        })
        .catch(err => {
          console.log('An error occurred: ' + err.message);
        });
    };

    const handleDelete = (idDelete) => {
      const userInfo = {
        type: "delete",
        CredentialId: idDelete
      };
    
      axios.post('https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/sandbox/users/neo', { requestInfo: userInfo })
        .then(res => {
          if (res.data.err) {
            console.log('Error: ' + res.data.err);
          } else {
            setUsersList(usersList.filter(us => us.CredentialId != res.data.deletedUser))
          }
        })
        .catch(err => {
          console.log('An error occurred: ' + err.message);
        });
    };

  return (
    <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
      <div className="navbar_transparent">
          <Link to="/"><img className="logo" src={LogoBlue} /></Link>
          <div className="btn_blue_check_container">
            <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
            <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
          </div>
            <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
              <span>{props.user.username}</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
            </div>
            {
              usernameOpen &&
              <div className="username_dropdown">
                <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
                <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                <div className="username_info">
                  <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
                  <p><span>{props.lang == "spanish" ? "Tipo " : "Type "}:</span> {props.user.type}</p>
                  { props.user.type != "user" && <Link to="/users"><span>{props.lang == "spanish" ? "Gestión de usuarios" : "User management"}</span></Link> }                </div>
                {/* <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div> */}
                <div className="btn_username_container">
                  <div className="btn_blue_check_container">
                    <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                    <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
                  </div>
                  <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
                </div>
              </div>
            }
      </div>

      <div className="content content_table">
        <div className="table_log_title">
          <h2>{props.lang == "spanish" ? "Gestión de usuarios" : "User Management"}</h2>
          <a href="#" onClick={() => toggleForm("", "", "", true, "", "user", true)} className="btn_blue btn_outline">{props.lang == "spanish" ? "Nuevo usuario" : "New user"}</a>
        </div>

        {
          showForm &&
          <div className="users_form">
            <input type="text" placeholder={props.lang == "spanish" ? "Nombre" : "Name"} value={name} onChange={(e) => setName(e.target.value)}/>
            <input type="email" placeholder={props.lang == "spanish" ? "Correo electrónico" : "Email"} value={email} onChange={(e) => setEmail(e.target.value)}/>
            {
              showPassword &&
              <input type="password" placeholder={props.lang == "spanish" ? "Contraseña" : "Password"} value={password} onChange={(e) => setPassword(e.target.value)}/>
            }
            <div className="users_flex">
              <span onClick={() => setType("admin")} className={type == "admin" ? "active" : ""}>{props.lang == "spanish" ? "Administrador" : "Admin"}</span>
              <span onClick={() => setType("user")} className={type == "user" ? "active" : ""}>{props.lang == "spanish" ? "Usuarios" : "Users"}</span>
            </div>
            <div className="users_form_btn" onClick={() => showPassword ? handleInsert() : handleUpdate()}>{
              showPassword ? (props.lang == "spanish" ? "Crear" : "Create") : (props.lang == "spanish" ? "Modificar" : "Update")
              }</div>
          </div>
        }

        <h3 className="content_table_subtitle">{props.lang == "spanish" ? "Administrador" : "Admin"}</h3>
        <div className="table_log table_users">
          <table>
            <thead>
              <tr>
                <td>{props.lang == "spanish" ? "Nombre" : "Name"}</td>
                <td>{props.lang == "spanish" ? "Correo electrónico" : "Email"}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {
                (!usersLoading && usersError == "" && usersList.length > 0) && (
                  usersList
                  .filter(user => user.Type == "admin")
                  .map(user => (
                    <tr>
                      <td>{user["Nickname"]}</td>
                      <td>{user["Email"]}</td>
                      <td>
                        <div className="users_flex_table">
                          <span onClick={() => toggleForm(user["CredentialId"], user["Nickname"], user["Email"], false, "", user["Type"], true)}>{props.lang == "spanish" ? "Editar" : "Edit"}</span>
                          {
                            props.user.username == user["Nickname"]
                            ?
                            <></>
                            :
                            <span onClick={() => handleDelete(user["CredentialId"])}>{props.lang == "spanish" ? "Administrador" : "Delete"}</span>
                          }
                        </div>

                      </td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </table>
        </div>
        <h3 className="content_table_subtitle">{props.lang == "spanish" ? "Usuarios" : "Users"}</h3>
        <div className="table_log table_users">
          <table>
            <thead>
              <tr>
                <td>{props.lang == "spanish" ? "Nombre" : "Name"}</td>
                <td>{props.lang == "spanish" ? "Correo electrónico" : "Email"}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {
                (!usersLoading && usersError == "" && usersList.length > 0) && (
                  usersList
                  .filter(user => user.Type == "user")
                  .map(user => (
                    <tr>
                      <td>{user["Nickname"]}</td>
                      <td>{user["Email"]}</td>
                      <td>
                        <div className="users_flex_table">
                          <span onClick={() => toggleForm(user["CredentialId"], user["Nickname"], user["Email"], false, "", user["Type"], true)}>{props.lang == "spanish" ? "Editar" : "Edit"}</span>
                          <span onClick={() => handleDelete(user["CredentialId"])}>{props.lang == "spanish" ? "Administrador" : "Delete"}</span>
                        </div>
                      </td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </table>
        </div>
        <h3 className="content_table_subtitle">{props.lang == "spanish" ? "Desarrolladores" : "Developers"}</h3>
        <div className="table_log table_users">
          <table>
            <thead>
              <tr>
                <td>{props.lang == "spanish" ? "Nombre" : "Name"}</td>
                <td>{props.lang == "spanish" ? "Correo electrónico" : "Email"}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {
                (!usersLoading && usersError == "" && usersList.length > 0) && (
                  usersList
                  .filter(user => user.Type == "dev")
                  .map(user => (
                    <tr>
                      <td>{user["Nickname"]}</td>
                      <td>{user["Email"]}</td>
                      <td></td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </table>
        </div>
      </div>


    </div>
  );

}

export default Users;