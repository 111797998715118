import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";

import ReactGA from "react-ga";
import LogoBlue from "../../media/logo_blue.svg";

function Demo(props) {

  let { endPointReference } = useParams();
  let navigate = useNavigate();

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [loginOpen, setLoginOpen] = useState(false);
  const [errorLogin, setErrorLogin] = useState("Login");
  const[forgotPassword, setForgotPassword] = useState(false);

  const [paramsObj, setParamsObj] = useState({});
  const [paramsError, setParamsError] = useState(false);

  const hideLoginForm = (e) => {
    if (e.target.className == "login_form") {
      setLoginOpen(false);
    }
  }

  const login = async () => {
    
    setErrorLogin("");
    if (emailLogin.trim().length > 0 && passwordLogin.length > 0) {
      try {
          const userLogin = {
            email: emailLogin,
            password: passwordLogin
          };

          setErrorLogin("Loading");
    
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/connexion-sandbox/neo`,
            userLogin
          )
            .then(res => {
              if (res.data.userId) {
                localStorage.setItem("user", JSON.stringify({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  apiKey: res.data.apiKey,
                  type: res.data.type,
                  username: res.data.username
                }));
                props.setIsLogged(true);
                props.setUser({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  apiKey: res.data.apiKey,
                  type: res.data.type,
                  username: res.data.username
                });
                navigate("/");
                window.location.reload(false);
              } else if (res.data.err){
                setErrorLogin("False credentials");
              }
            })

        } catch (error) {
          setErrorLogin("An error occurred, please try again later");
          setErrorLogin("Try again");
        }
    } else {
      setErrorLogin("A field is missing");
    }
  }

  const TEXT = {
    "phone": {
      title: "Validar Número de Teléfono",
      description: "Investigar un número de teléfono en profundidad. Formato requerido: +36301234567 o 0036301234567",
      titleEnglish: "Validate Phone Number",
      descriptionEnglish: "Investigate a phone number in depth. Required format: +36301234567 or 0036301234567"
    },
    "ip": {
      titleEnglish: "IP Address",
      descriptionEnglish: "The IP verification API determines the probability that an IP address is spoofed using modern computing techniques, so you can block TOR, VPN and proxy users.",
      title: "Dirección IP",
      description: "La API IP verificación determina la probabilidad de que se falsifique una dirección IP utilizando técnicas informáticas modernas, de modo que pueda bloquear TOR, VPN y usuarios de proxy."
    },
    "curp": {
      title: "Validación de CURP",
      description: "Validar la CURP a través de RENAPO",
      titleEnglish: "Validation of CURP",
      descriptionEnglish: "Validate the CURP through RENAPO."
    },
    "email": {
      title: "Comprobación completa del correo electrónico",
      description: "Agrega cientos de fuentes de datos en redes sociales abiertas y accesibles para proporcionar una herramienta de investigación de emails en profundidad.",
      titleEnglish: "Full Email Check",
      descriptionEnglish: "Aggregates hundreds of open and accessible social network data sources to provide an in-depth email research tool."
    },
    "nss-imss": {
      title: "Validar INE/IFE",
      description: "Validar los datos de una credencial en la lista nominal del INE.",
      titleEnglish: "Validate INE/IFE",
      descriptionEnglish: "Validate the data of a credential in the INE nominal list."
    }
  }

  const REQUESTS = {
    "curp": [
      {
        name: "curp*",
        placeholder: "curp",
        placeholderEnglish: "curp",
        value: "RAZR811011HVZMPB01"
      }
    ],
    "nss-imss": [
      {
        name: "cic*",
        placeholder: "cic*",
        placeholderEnglish: "cic*",
        value: "173438871"
      },
      {
        name: "identificadorCiudadano*",
        placeholder: "identificadorCiudadano*",
        placeholderEnglish: "identificadorCiudadano*",
        value: "098794022"
      }
    ],
    "ip": [
        {
          name: "ip*",
          placeholder: "ip*",
          placeholderEnglish: "ip*",
          value: "1.1.1.1"
        }
      ],
    "phone": [
      {
        name: "phone*",
        placeholder: "phone*",
        placeholderEnglish: "phone*",
        value: "36200130525"
      }
    ],
    "email": [
      {
        name: "email*",
        placeholder: "email*",
        placeholderEnglish: "email*",
        value: "test2023-10-30ev2@gmail.com"
      }
    ]
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    let params = {};
    REQUESTS[endPointReference]
    .forEach(param => { params[param.name] = "" })
    setParamsObj(params)
  }, []);

  const updateParams = (field, value) => {
    let newParam = paramsObj;
    newParam[field] = value;
    setParamsObj(newParam)
  }

  const checkDemoParams = () => {
    if (
      REQUESTS[endPointReference]
      .map(param => param.value == paramsObj[param.name])
      .includes(false)
    ) {
      setParamsError(true);
    } else {
      setParamsError(false);
      navigate("/api-result/" + endPointReference);
    }
  }

  return (
    <div className="container">
      <div className="navbar_transparent">
        <Link to="/"><img className="logo" src={LogoBlue} /></Link>
        <Link to="/" className="btn_blue btn_hide_mobile">
          <svg
              fill="#fff"
              width="21px"
              height="21px"
          >
            <path class="cls-1" d="M13.7,23.7h-5a3,3,0,0,1-3-3v-6h-1a1,1,0,0,1-.92-.62A1,1,0,0,1,4,13l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,.21,1.09,1,1,0,0,1-.92.62h-2a1,1,0,0,1-.35-1.94L13.7,6.11,7.05,12.76a1,1,0,0,1,.65.94v7a1,1,0,0,0,1,1h5a1,1,0,0,1,0,2Z" transform="translate(-3.7 -3.7)"/><path class="cls-1" d="M20.7,24.7a4,4,0,1,1,4-4A4,4,0,0,1,20.7,24.7Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,20.7,18.7Z" transform="translate(-3.7 -3.7)"/><path class="cls-1" d="M10.7,23.7a1,1,0,0,1-1-1v-6a3,3,0,0,1,3-3h2a3,3,0,0,1,2.37,1.15,1,1,0,0,1-1.58,1.24,1,1,0,0,0-.79-.39h-2a1,1,0,0,0-1,1v6A1,1,0,0,1,10.7,23.7Z" transform="translate(-3.7 -3.7)"/>
          </svg>
          {props.lang == "spanish" ? "Inicio" : "Home"}
        </Link>
        <div className="btn_blue_check_container">
          <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
          <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
        </div>
        <div className="btn_blue" onClick={() => setLoginOpen(true)}>{props.lang == "spanish" ? "Iniciar sesión" : "Sign in"}</div>
        {/* <Link to="/signup" className="btn_blue btn_outline btn_hide_mobile" style={{marginLeft: "10px"}}>{props.lang == "spanish" ? "Inscribirse" : "Sign Up"}</Link> */}
      </div>
      <div className="home_page">
        <img className="logo_blue" />
        <div className="home_flex">
            <div className="home_welcome">
                <h1>{props.lang == "spanish" ? "¡HOLA! ESTO ES " + TEXT[endPointReference].title : "HELLO! THIS IS " + TEXT[endPointReference].titleEnglish}</h1>
                <p>{
                  props.lang == "spanish" ?
                  <>{TEXT[endPointReference].description}<br/>Con nuestra solución puedes validar información de manera individual o por lotes.<br/>Todas nuestras fuentes de información se actualizan constantemente con información oficial.</>
                  :
                  <>{TEXT[endPointReference].descriptionEnglish}<br/>With our solution you can validate information individually or in batches.<br />All our information sources are constantly updated with official information.</>
                }</p>
                <div className="home_action">
                    <p>{props.lang == "spanish" ? "¿Te gustaría comenzar a validar información real?" : "Would you like to start validating real information?"}</p>
                    <div className="btn_blue" onClick={() => setLoginOpen(true)}>{props.lang == "spanish" ? "Comenzar ahora" : "Start now"}</div>
                    <p className="small">{props.lang == "spanish" ? <><span>¿Tienes dudas?</span> <a href=" mailto:info@danflor.mx">Contáctanos</a></> : <><span>Do you have any doubts?</span> <a href="mailto:info@danflor.mx">Contact us</a></>}</p>
                </div>
            </div>
            <div className="test_form">
                <h3>{props.lang == "spanish" ? "Realiza una verificación de prueba con esta información" : "Perform a test check with this information" }</h3>
                <div className="diviser"></div>
                {
                  REQUESTS[endPointReference].map(param =>
                    <p><span>{param.name}:</span>{param.value}</p>
                  )
                }
                {
                  REQUESTS[endPointReference].map(param =>
                    <input className="send_request_input" placeholder={props.lang == "spanish" ? param.placeholder : param.placeholderEnglish} onChange={(e) => updateParams(param.name, e.target.value)}/>
                  )
                }
                <span className="send_request_info">
                    {props.lang == "spanish" ? "* Campos obligatorios" : "* Required fields"}
                </span>
                {paramsError ? <span className="send_request_error">{props.lang == "spanish" ? "Comprobar datos" : "Recheck fields's value"}</span> : <></>}
                <div className="btn_white" onClick={() => checkDemoParams()}>Probar</div>
            </div>
        </div>
        {loginOpen && <div className="login_form" onClick={(e) => hideLoginForm(e)}>
          <div className="login_form_contaire">
          { forgotPassword ?
            <>
              <h1>{props.lang == "spanish" ? "Contraseña olvidada" : "Forgot password"}</h1>
              <input
                className="send_request_input"
                placeholder={props.lang == "spanish" ? "Usuario" : "Address email"}
              />
              <div className="btn_white">
                {props.lang == "spanish" ? "Solicitar" : "Request"}
                
              </div>

              <a onClick={() => setForgotPassword(false)}>{props.lang == "spanish" ? "Inicio de sesión" : "Login"}</a>


            </>
            :
            <>
            <svg onClick={() => setLoginOpen(false)} className="login_form_contaire_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            <h1>{props.lang == "spanish" ? "Iniciar sesión" : "Sign in"}</h1>
            <input
              className="send_request_input"
              placeholder={props.lang == "spanish" ? "Usuario" : "Address email"}
              onChange={e => {setEmailLogin(e.target.value.trim());setErrorLogin("Login");}}
              onKeyPress={e => {if (e.key === "Enter") login()}}
            />
            <input
              className="send_request_input"
              type="password"
              placeholder={props.lang == "spanish" ? "Contrasena" : "Password"}
              onChange={e => {setPasswordLogin(e.target.value);setErrorLogin("Login");}}
              onKeyPress={e => {if (e.key === "Enter") login()}}
            />
            <div className={"btn_white" + (errorLogin != "Login" ? (errorLogin == "Loading" ? " btn_white_loading" : " btn_white_error") : "")} onClick={() => login()}>
              {
                props.lang == "english" ? errorLogin :
                  errorLogin == "Login" ? "Entrar" :
                  errorLogin == "Loading" ? "Cargando" :
                  errorLogin == "False credentials" ? "Credenciales falsas" :
                  errorLogin == "Try again" ? "Inténtalo de nuevo" :
                  errorLogin == "A field is missing" ? "Falta un campo" : ""
              }
            </div>

            {/* <div className="btn_social_container"><div className="btn_white btn_social">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"></path></defs><clipPath id="b"><use xlinkHref="#a" overflow="visible"></use></clipPath><path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"></path><path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"></path><path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"></path><path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"></path></svg>
            
            {props.lang == "spanish" ? "Iniciar sesión con Google" : "Sign in using Google"}            
            </div><div className="btn_white btn_social"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"></path></svg>
            {props.lang == "spanish" ? "Iniciar sesión con Github" : "Sign in using Github"}</div></div> */}

            <a onClick={() => setForgotPassword(false)}>{props.lang == "spanish" ? "Olvidé mi contraseña" : "I forgot my password"}</a>
            </>
            }          </div>
        </div>}
      </div>
    </div>
  );

}

export default Demo;