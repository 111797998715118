import React from "react";

function AdobeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 -1.5 27 27"
    >
      <path d="M17.097 0h10.025v24zM10.034 0H0v24zM9.181 19.171l4.384-10.329 6.386 15.156h-4.184l-1.91-4.827z"></path>
    </svg>
  );
}

export default AdobeIcon;
