import React from "react";

function KomootIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 24 24"
    >
      <path d="M9.8 14.829l2.2-3.43 2.2 3.43 5.962 5.962A11.946 11.946 0 0112 24c-3.043 0-5.935-1.14-8.162-3.209zM0 12C0 5.385 5.385 0 12 0c6.62 0 12 5.385 12 12 0 2.663-.855 5.175-2.469 7.284l-6.018-6.018c.15-.412.226-.839.226-1.27A3.743 3.743 0 0012 8.257a3.743 3.743 0 00-3.739 3.739c0 .431.075.858.226 1.27l-6.018 6.018A11.865 11.865 0 010 12z"></path>
    </svg>
  );
}

export default KomootIcon;
