import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import ReactGA from "react-ga";

import LogoBlue from "../../media/logo_blue.svg";

import Chile from "../../media/ICON_CHILE.png";
import Colombia from "../../media/ICON_COLOMBIA.png";
import Global from "../../media/ICON_MUNDO.png";
import Peru from "../../media/ICON_PERU.png";
import Ecuador from "../../media/ICON_ECUADOR.png";
import Venezuela from "../../media/ICON_VLA.png";
import Mexico from "../../media/ICON_MEX.png";

import ApiIcon from "../../media/api_icon.png";

import { LoginSocialGoogle, LoginSocialGithub } from 'reactjs-social-login';

function ApiList(props) {

  let navigate = useNavigate();

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [loginOpen, setLoginOpen] = useState(false);
  const [errorLogin, setErrorLogin] = useState("Login");

  const[forgotPassword, setForgotPassword] = useState(false);
  const[emailForgotPassword, setEmailForgotPassword] = useState("");

  const[passwordKeyOpen, setPasswordKeyOpen] = useState(false);
  const[passwordKey, setPasswordKey] = useState("");
  const[errorPasswordKey, setErrorPasswordKey] = useState("Confirm");

  const[changePasswordOpen, setChangePasswordOpen] = useState(false);
  const[changePassword, setChangePassword] = useState("");
  const[errorChangePassword, setErrorChangePassword] = useState("Reset");
  

  const [usernameOpen, setUsernameOpen] = useState(false);
  const [apiListFilter, setApiListFilter] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const login = async () => {
    setErrorLogin("");
    if (emailLogin.trim().length > 0 && passwordLogin.length > 0) {
      try {
          const userLogin = {
            email: emailLogin,
            password: passwordLogin
          };

          setErrorLogin("Loading");
    
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/connexion-sandbox/neo`,
            userLogin
          )
            .then(res => {
              if (res.data.userId) {
                localStorage.setItem("user", JSON.stringify({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  apiKey: res.data.apiKey,
                  type: res.data.type,
                  username: res.data.username
                }));
                props.setIsLogged(true);
                props.setUser({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  apiKey: res.data.apiKey,
                  type: res.data.type,
                  username: res.data.username
                });
                window.location.reload(true);
              } else if (res.data.err){
                setErrorLogin("False credentials");
              }
            })

        } catch (error) {
          setErrorLogin("Try again");
        }
    } else {
      setErrorLogin("A field is missing");
    }
  }

  const sendForgotPassword = async () => {
    try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/forgot-password`,
          { email: emailForgotPassword }
        )
          .then(() => {
            setPasswordKeyOpen(true);
            setForgotPassword(false);
          })

      } catch (error) {
        setPasswordKeyOpen(true);
        setForgotPassword(false);
      }
      setPasswordKeyOpen(true);
      setForgotPassword(false);
  }

  const sendPasswordKey = async () => {
    setErrorPasswordKey("");
    try {
      console.log(
        {
          email: emailForgotPassword,
          key: passwordKey
        }

      );
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/pass-key`,
          {
            email: emailForgotPassword,
            key: passwordKey
          }
        )
          .then((res) => {
            if (res.data.succ){
              setChangePasswordOpen(true);
              setPasswordKeyOpen(false);
              setErrorPasswordKey("Confirm");
            } else if (res.data.err) {
              setErrorPasswordKey(res.data.err);
            } else {
              setErrorPasswordKey("Try again");
            }
          })

      } catch (error) {
        setErrorPasswordKey("Try again");
      }
  }

  const sendNewPassword = async () => {
    setErrorChangePassword("");
    if (changePassword.length == 0) {
      setErrorChangePassword("A field is missing");
    } else {
      try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/new-pass`,
          {
            email: emailForgotPassword,
            password: changePassword
          }
        )
          .then((res) => {
            if (res.data.succ){
              setChangePasswordOpen(false);
              setErrorChangePassword("Reset");
            } else {
              setErrorChangePassword("Try again");
            }
          })
      } catch (error) {
        setErrorChangePassword("Try again");
      }
    }
  }

  const hideLoginForm = (e) => {
    if (e.target.className == "login_form") {
      setLoginOpen(false);
      setForgotPassword(false)
      setPasswordKeyOpen(false)
      setEmailForgotPassword("")
      setPasswordKey("")
    }
  }

  const hideUsernameDropdown = (e) => {
    if (!(e.target.closest('.username'))) {
      if (!(e.target.closest('.username_dropdown'))) {
        setUsernameOpen(false)
      }
    }
    if (!(e.target.closest('.filter_dropdown_title'))) {
      if (!(e.target.closest('.filter_dropdown_list'))) {
        setFilterOpen(false)
      }
    }
  }



  return (
    <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
      <div className="navbar_transparent">
          <Link to="/"><img className="logo" src={LogoBlue} /></Link>
          <div className="btn_blue_check_container">
            <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
            <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
          </div>
          {
            props.isLogged
            ? <>
            <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
              <span>{props.user.username}</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
            </div>
            {
              usernameOpen &&
              <div className="username_dropdown">
                <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
                <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                <div className="username_info">
                  <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
                  <p><span>{props.lang == "spanish" ? "Tipo " : "Type "}:</span> {props.user.type}</p>
                  { props.user.type != "user" && <Link to="/users"><span>{props.lang == "spanish" ? "Gestión de usuarios" : "User management"}</span></Link> }
                </div>
                {/* <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div> */}
                <div className="btn_username_container">
                  <div className="btn_blue_check_container">
                    <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                    <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
                  </div>
                  <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
                </div>
              </div>
            }
            </>
            : <><div className="btn_blue" onClick={() => setLoginOpen(true)}>{props.lang == "spanish" ? "Iniciar sesión" : "Sign in"}</div>
              {/* <Link to="/signup" className="btn_blue btn_outline btn_hide_mobile" style={{marginLeft: "10px"}}>{props.lang == "spanish" ? "Inscribirse" : "Sign Up"}</Link> */}
              </>
          }
      </div>
      <div className="api_list_page">
        <img className="logo_blue" />
        <div className="home_flex home_flex_column">
          <h2>{props.lang == "spanish" ? "VERIFICAR USUARIOS, EMPRESAS Y AUTOS EN MÉXICO NUNCA FUE TAN FÁCIL." : "VERIFYING USERS, COMPANIES AND CARS IN MEXICO HAS NEVER BEEN SO EASY."}</h2>
          <p>{props.lang == "spanish" ?
            <>Sin necesidad de escribir una línea de código, de manera fácil, práctica y rápida valida datos de forma individual o por lotes.<br />Nuestra plataforma está conectada a las mejores fuentes de datos oficiales y alternativas en México con las cuales podrás validar datos de personas, empresas y autos en minutos.</>
            : 
            <>Without the need to write a line of code, it easily, practically and quickly validates data individually or in batches.<br />Our platform is connected to the best official and alternative data sources in Mexico with which you will be able to validate data of people, companies and cars in minutes.</>
          }</p>
          <div className={"filter_dropdown_container" + (!props.isLogged ? " filter_dropdown_container_demo" : "")}>        
            <div class="filter_dropdown">
              <div class="filter_dropdown_title" onClick={() => setFilterOpen(!filterOpen)}>
                <span>{apiListFilter.length == 0 ? "Filtrar Por" : "Data "+ apiListFilter}</span>
                <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="7 6 10 12" width="18px" fill="#2038dd"><path xmlns="http://www.w3.org/2000/svg" d="M0 0h24v24H0z" fill="none"></path><path xmlns="http://www.w3.org/2000/svg" d="M7 10l5 5 5-5z"></path></svg>
              </div>
              {
                filterOpen ?
                  <div class="filter_dropdown_list">
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("Global");setFilterOpen(false);}}>Data Global</div>
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("México");setFilterOpen(false);}}>Data México</div>
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("Perú");setFilterOpen(false);}}>Data Perú</div>
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("Colombia");setFilterOpen(false);}}>Data Colombia</div>
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("Ecuador");setFilterOpen(false);}}>Data Ecuador</div>
                    <div class="filter_dropdown_item" onClick={() => {setApiListFilter("");setFilterOpen(false);}}>Todas</div>
                  </div>
                : <></>
              }
            </div>
          </div>
          <div className={"api_list" + (!props.isLogged ? " api_list_demo" : "")}>        
          {
            props.methodsList.length > 0 && 
            (
              props.isLogged ?
              Object.values(
                props.methodsList.reduce(function(rv, x) {
                  (rv[x['ApiName']] = rv[x['ApiName']] || []).push(x);
                  return rv;
                }, {})  
              )
              .filter(method => apiListFilter.length > 0 ? method[0].Country.includes(apiListFilter) : method[0])
              .sort(api => {
                if (api[0].Country.includes("México") && api[0].Country.length == 1) {
                  return -1
                } else {
                  return 1
                }
              })
              .map((api, index) => 
                (api[0]?.ApiName)
                ?
                (
                  api[0].ApiName.toLowerCase() != "moffin" && 
                  api[0].ApiName.toLowerCase() != "facturapi" &&  
                  api[0].ApiName.toLowerCase() != "weesing" && 
                  api[0].ApiName.toLowerCase() != "weesign" && 
                  api[0].ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) " && 
                  api[0].ApiName.toUpperCase() != "EXCHANGE RATES" && 
                  api[0].ApiName.toLowerCase() != "palenca" &&
                  api[0].ApiName.toLowerCase() != "copomex" &&
                  api[0].ApiName.toLowerCase() != "auto api mapas" &&
                  api[0].ApiName.toUpperCase() != "PERSON ANALYTICS"
                ) ?
                <div className="api_container">
                  <h1 onClick={() => console.log(api[0].ApiName)}>{api[0].ApiName}</h1>
                                    {
                    api.map((method, index) => 
                      method.EndPointId != "c094b8f5-6e8c-4e91-bf0f-6424c7b45117"
                      ?
                      <Link
                        key={index}
                        className="api_item"
                        to={ props.isLogged ? "/api-request/" + method.EndPointId : "/demo-request/" + method.EndPointId }
                      >
                        <div className="api_icon">
                          <img src={ApiIcon}/>
                        </div>
                        <span>{props.lang == "spanish" ? method.Name : method.NameEnglish}</span>
                        <div className={"countries countries_" + (method.Country.filter(country => ["Chile", "Colombia", "Venezuela", "Perú", "México", "Global"].includes(country)).length)}>
                        {
                          method.Country.map((country, index) =>
                          (["Chile", "Colombia", "Venezuela", "Perú", "México", "Global", "Ecuador"].includes(country)) ?
                            <img className="country_icon" src={
                              country == "México" ? Mexico
                              : country == "Colombia" ? Colombia
                              : country == "Venezuela" ? Venezuela
                              : country == "Perú" ? Peru
                              : country == "Ecuador" ? Ecuador
                              : country == "Chile" ? Chile
                              : Global
                            } />
                            : <></>
                          )
                        }
                        </div>
                      </Link>  
                      : <></>            
                    )                
                  }
                </div>
                : <></>
                : <></>
              )
              :
                props.methodsList
                .filter(method => apiListFilter.length > 0 ? method.Country.includes(apiListFilter) : method)
                .map((method, index) => 
                <Link
                  key={index}
                  className="api_item"
                  to={ props.isLogged ? "/api-request/" + method.EndPointId : "/demo-request/" + method.EndPointId }
                >
                  <div className="api_icon">
                    <img src={ApiIcon}/>
                  </div>
                  <span>{props.lang == "spanish" ? method.Name : method.NameEnglish}</span>
                  <img className="country_icon" src={
                    method.Country.length > 1 ? Global
                    : method.Country[0] == "México" ? Mexico
                    : method.Country[0] == "Chile" ? Chile
                    : method.Country[0] == "Colombia" ? Colombia
                    : method.Country[0] == "Venezuela" ? Venezuela
                    : method.Country[0] == "Perú" ? Peru
                    : method.Country[0] == "Ecuador" ? Ecuador
                    : Global
                  } />
                </Link>              
              ) 
              )
          }
          </div>
          <div className="api_info">
              <span>{props.lang == "spanish" ? "¿Tienes dudas de cómo usar easycheck?" : "Do you have doubts about how to use easycheck?"}</span>
              <span>{props.lang == "spanish" ? <><a href="#">Escríbenos</a> o Agenda un demo</> : <><a href="#">Contact us</a> or Schedule a demo</>}</span>
              <a href="#" className="btn_blue btn_large">{props.lang == "spanish" ? "Agenda un demo" : "Schedule a demo"}</a>
          </div>
          <div className="footer">
              <a href="https://www.scorce.io/politica-de-privacidad" target="_blank">{props.lang == "spanish" ? "Politica de Privacídad" : "Privacy Policy"}</a>
              <a href="https://www.scorce.io/terminos-y-condiciones" target="_blank">{props.lang == "spanish" ? "Terminos y Condiciones" : "Terms and Conditions"}</a>
          </div>
        </div>
        {!(props.isLogged) && loginOpen && <div className="login_form" onClick={(e) => hideLoginForm(e)}>
          <div className="login_form_contaire">

          { 
            changePasswordOpen ?
            <>
              <h1>{props.lang == "spanish" ? "Restablecer contraseña" : "Reset password"}</h1>
              <input
                className="send_request_input"
                placeholder={props.lang == "spanish" ? "Nueva contraseña" : "New password"}
                onChange={e => {setChangePassword(e.target.value.trim());setErrorChangePassword("Reset")}}
                value={changePassword}
                type="password"
              />
              <div className={
                "btn_white"
                + (errorChangePassword == "" ? " btn_white_loading" : errorChangePassword != "Reset" ? " btn_white_error" : "")
              } onClick={() => sendNewPassword()}>
                {
                  props.lang == "spanish" ?
                  (
                    errorChangePassword == "Reset" ? "Reset" :
                    errorChangePassword == "A field is missing" ? "A field is missing" :
                    errorChangePassword == "Try again" ? "Try again" : "Loading"
                  )
                  :
                  (
                    errorChangePassword == "Reset" ? "Reset" :
                    errorChangePassword == "A field is missing" ? "A field is missing" :
                    errorChangePassword == "Try again" ? "Try again" : "Loading"
                  )
                }
              </div>
            </>
            : passwordKeyOpen ?
            <>
              <h1>{props.lang == "spanish" ? "Verificación" : "Verification"}</h1>
              <input
                className="send_request_input"
                placeholder="X X X X X X" 
                onChange={e => {setPasswordKey(e.target.value.trim());setErrorPasswordKey("Confirm")}}
                value={passwordKey}
              />
              <div className={
                "btn_white"
                + (errorPasswordKey == "" ? " btn_white_loading" : errorPasswordKey != "Confirm" ? " btn_white_error" : "")
              } onClick={() => sendPasswordKey()}>
                {
                  props.lang == "spanish" ?
                  (
                    errorPasswordKey == "Confirm" ? "Confirm" :
                    errorPasswordKey == "Code false" ? "Code false" :
                    errorPasswordKey == "Try again" ? "Try again" :
                    errorPasswordKey == "Try end" ? "Try end" : "Loading"
                  )
                  :
                  (
                    errorPasswordKey == "Confirm" ? "Confirm" :
                    errorPasswordKey == "Code false" ? "Code false" :
                    errorPasswordKey == "Try again" ? "Try again" :
                    errorPasswordKey == "Try end" ? "Try end" : "Loading"
                  )
                }
              </div>
              <a onClick={() => {setPasswordKeyOpen(false);setEmailForgotPassword("");setPasswordKey("");}}>{props.lang == "spanish" ? "Inicio de sesión" : "Login"}</a>
            </>
            : forgotPassword ?
            <>
              <h1>{props.lang == "spanish" ? "Contraseña olvidada" : "Forgot password"}</h1>
              <input
                className="send_request_input"
                placeholder={props.lang == "spanish" ? "Usuario" : "Address email"}              
                onChange={e => setEmailForgotPassword(e.target.value.trim())}
                value={emailForgotPassword}
              />
              <div className="btn_white" onClick={() => sendForgotPassword()}>
                {props.lang == "spanish" ? "Solicitar" : "Request"}                
              </div>

              <a onClick={() => {setForgotPassword(false);setEmailForgotPassword("");setPasswordKey("");}}>{props.lang == "spanish" ? "Inicio de sesión" : "Login"}</a>
            </>
            :
            <>
            <svg onClick={() => setLoginOpen(false)} className="login_form_contaire_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            <h1>{props.lang == "spanish" ? "Iniciar sesión" : "Sign in"}</h1>
            <input
              className="send_request_input"
              placeholder={props.lang == "spanish" ? "Usuario" : "Address email"}
              onChange={e => {setEmailLogin(e.target.value.trim());setErrorLogin("Login");}}
              onKeyPress={e => {if (e.key === "Enter") login()}}
            />
            <input
              className="send_request_input"
              type="password"
              placeholder={props.lang == "spanish" ? "Contrasena" : "Password"}
              onChange={e => {setPasswordLogin(e.target.value);setErrorLogin("Login");}}
              onKeyPress={e => {if (e.key === "Enter") login()}}
            />
            <div className={"btn_white" + (errorLogin != "Login" ? (errorLogin == "Loading" ? " btn_white_loading" : " btn_white_error") : "")} onClick={() => login()}>
              {
                props.lang == "english" ? errorLogin :
                  errorLogin == "Login" ? "Entrar" :
                  errorLogin == "Loading" ? "Cargando" :
                  errorLogin == "False credentials" ? "Credenciales falsas" :
                  errorLogin == "Account not found" ? "Cuenta no encontrada" :
                  errorLogin == "Try again" ? "Inténtalo de nuevo" :
                  errorLogin == "A field is missing" ? "Falta un campo" : ""
              }
            </div>

{/* 
            <div className="btn_social_container">
              <LoginSocialGoogle
                client_id={"713200095474-cmvddqorhb9ktpocfeuq39rdgl5tujpk.apps.googleusercontent.com"}
                onResolve={({ provider, data }) => {
                  loginWithEmail(data.email);                  
                }}
                onReject={(err) => {
                  console.log(err)
                }}
                scope="openid profile email"
                discoverDocs="claims_supported"
                access_type="offline"
              >
                <div className="btn_white btn_social">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"></path></defs><clipPath id="b"><use xlinkHref="#a" overflow="visible"></use></clipPath><path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"></path><path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"></path><path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"></path><path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"></path></svg>
                  {props.lang == "spanish" ? "Iniciar sesión con Google" : "Sign in using Google"}            
                </div>
              </LoginSocialGoogle>
              
              <LoginSocialGithub
                 isOnlyGetToken
                 client_id={"7221c1935b604e23883d"}
                 client_secret={"4f8c406601265957e25d6ad010c31aeb063338bb"}
                 redirect_uri=""
                 scope="repo,gist,user:email"
                 onResolve={({ provider, data }) => {
                  setGithubLogin(data);
                 }}
                 onReject={(err) => {
                   console.log(err)
                 }}
              >
                <div className="btn_white btn_social">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"></path></svg>
                  {props.lang == "spanish" ? "Iniciar sesión con Github" : "Sign in using Github"}
                </div>
              </LoginSocialGithub>
            </div>
             */}
            <a onClick={() => setForgotPassword(true)}>{props.lang == "spanish" ? "Olvidé mi contraseña" : "I forgot my password"}</a>
            </>
            }
          </div>
        </div>}
      </div>
    </div>
  );

}

export default ApiList;