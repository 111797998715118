import React from "react";

function NetflixIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M7.188.005l6.333 17.948v-.01l.5 1.411c2.786 7.88 4.281 12.104 4.286 12.109l.938.057c1.542.063 3.453.24 4.901.411.333.042.62.052.641.042L17.928 12.52l-3.234-9.135A354.017 354.017 0 0013.538.119l-.042-.12H7.194zm11.343.011l-.016 7.073-.01 7.078-.583-1.646-.75 15.745c.74 2.089 1.135 3.203 1.141 3.208s.427.036.932.057c1.547.068 3.453.24 4.906.417.333.036.625.052.641.036.021-.01.026-7.224.026-16.01L24.808.016zM7.188.005v15.984c0 8.792.01 15.995.021 16.005s.552-.042 1.208-.115a54.66 54.66 0 012.01-.214c.693-.068 2.76-.203 3-.203.068 0 .073-.359.083-6.771l.01-6.776.505 1.417.177.5.76-15.734-.255-.729L13.493.004z"></path>
    </svg>
  );
}

export default NetflixIcon;
