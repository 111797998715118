import React from "react";

function TaringaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="#002E71"
      version="1.1"
      viewBox="0 0 98.625 98.625"
      xmlSpace="preserve"
    >
      <path d="M0 46.122L4.296 46.122 4.296 57.812 8.638 57.812 8.638 46.122 12.936 46.122 12.936 41.896 0 41.896z"></path>
      <path d="M23.12 41.67h-4.64c-4.467 0-4.342 5.18-4.342 5.18v10.962h4.774v-5.016H22.7v5.016h4.776V46.851c.001 0 .001-5.181-4.356-5.181zm-.419 7.965h-3.788v-3.289h3.788v3.289zM87.587 41.67h-4.64c-4.467 0-4.342 5.18-4.342 5.18v10.962h4.773v-5.016h3.789v5.016h4.775V46.851c.003 0 .003-5.181-4.355-5.181zm-.418 7.965H83.38v-3.289h3.789v3.289zM41.414 48.37v-3.526s-.479-2.949-3.308-2.949h-8.698v15.918h4.342v-3.908h1.339l1.836 3.908h4.696L39.37 52.48s2.044-.66 2.044-4.11zm-4.231.962h-3.601v-3.378h3.602l-.001 3.378z"></path>
      <path d="M43.16 41.896H47.501999999999995V57.813H43.16z"></path>
      <path d="M63.059 41.896L58.762 41.896 58.762 50.244 54.018 41.896 49.376 41.896 49.376 57.812 53.689 57.812 53.689 49.474 58.418 57.812 63.074 57.812 63.059 57.793z"></path>
      <path d="M76.125 46.125h-.003l.003.063v-.063z"></path>
      <path d="M70.348 51.328h2.342v2.707h-4.071v-7.914l7.504.004-.005-4.231h-7.484c-4.611 0-4.355 4.227-4.355 4.227v8.773s.631 2.918 5.554 2.918h2.217c4.697 0 5.025-2.938 5.025-2.938v-6.496H70.35v2.949h-.002z"></path>
      <path d="M94.314 40.812H98.625V52.400999999999996H94.314z"></path>
      <path d="M94.314 53.312H98.624V57.812999999999995H94.314z"></path>
    </svg>
  );
}

export default TaringaIcon;
