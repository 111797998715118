import React from "react";

function YahooIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path
        fillRule="evenodd"
        d="M496.622 161.516l-58.309 12.424c-8.666 2.454-32.237 19.813-73.159 53.318-43.42 34.737-65.752 57.049-69.465 68.233l-2.48 30.997-1.25 18.627 4.963 47.106 76.898 1.231-1.24 23.563H247.315l-111.635 1.259 3.74-22.34 35.955-1.231c18.618-1.25 29.792-3.713 33.505-7.462 2.463-2.444 3.713-17.332 3.713-43.395v-16.1l-1.25-32.256c-2.463-7.453-22.335-34.728-60.766-81.87-39.682-48.356-65.743-76.908-78.14-86.843L.5 116.863V95.764c3.712-2.463 43.412-2.463 116.589-1.223 50.842-1.241 93.018-1.241 126.509 1.223l-2.481 17.405-75.649 7.426c23.54 34.718 59.531 83.12 107.891 143.872 63.271-48.392 96.73-79.379 97.98-93.008l-64.493-9.942-4.963-22.313h107.905l101.711 1.24-14.877 21.072z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default YahooIcon;
