import React from "react";

function YandexIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-5.5 0 24 24"
    >
      <path d="M5.2 24v-7.786L0 2.25h2.616L6.45 13.017 10.86-.001h2.405L7.607 16.302v7.697z"></path>
    </svg>
  );
}

export default YandexIcon;
