import React from "react";

function CodecademyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M9.742 14.998a2.008 2.008 0 011.741.77l.003.005a.199.199 0 00.288.043l1.292-1.15c.094-.055.072-.205.025-.28a4.287 4.287 0 00-3.43-1.554h.006a4 4 0 00-4.059 2.728l-.008.028a7.745 7.745 0 00-.3 2.489v-.015a7.329 7.329 0 00.333 2.526l-.014-.052A3.985 3.985 0 009.7 23.289l-.013.001a4.346 4.346 0 003.426-1.514l.005-.006a.2.2 0 00.047-.131.204.204 0 00-.066-.151l-1.296-1.143a.201.201 0 00-.28.034 2.19 2.19 0 01-1.742.864l-.079-.001h.004a1.798 1.798 0 01-1.833-1.281l-.003-.013a5.66 5.66 0 01-.207-1.867l-.001.014a5.705 5.705 0 01.235-1.877l-.01.04a1.841 1.841 0 011.862-1.259h-.005zM3.276 25.464v.014c0 .08.049.148.119.176H19.195a.19.19 0 00.121-.176l-.001-.017v.001V6.506v-.014a.183.183 0 00-.122-.173H3.396a.187.187 0 00-.12.175v.013-.001l-.004 18.957zM21.577 4.082c.02.041.04.091.056.142l.002.008V27.726a.205.205 0 01-.047.13.21.21 0 01-.161.075H1.194a.185.185 0 01-.122-.025h.001a.226.226 0 01-.07-.164V4.209l-.001-.015c0-.079.05-.146.119-.172H21.419c.053 0 .103.016.145.043l-.001-.001v.02zm9.202 21.576h-7.005a.2.2 0 00-.199.216v-.001 1.906c0 .11.09.2.2.2l.016-.001h-.001 6.99a.189.189 0 00.215-.187l-.001-.018v.001-1.893l.001-.02a.19.19 0 00-.19-.19l-.028.002h.001v-.015z"></path>
    </svg>
  );
}

export default CodecademyIcon;
