import React from "react";

function EbayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M6.981 15.595v-.013a1.932 1.932 0 00-2.165-1.918l.009-.001a2.07 2.07 0 00-2.268 1.926v.006zm5.436 3.142h.041a2.313 2.313 0 002.303-2.534l.001.009a2.37 2.37 0 10-4.728.008v-.007a2.312 2.312 0 002.302 2.524h.043-.002.04zm7.573-2.343c-1.368.042-2.212.291-2.212 1.187 0 .581.45 1.212 1.631 1.212a2.134 2.134 0 002.417-2.273v.007-.162c-.562 0-1.237.008-1.854.027h.015zm-11.418-.229v-6.148h1.5v3.781a3.398 3.398 0 012.762-1.132l-.01-.001.113-.002a3.424 3.424 0 013.421 3.576v-.007.06c0 .274-.027.542-.08.8l.004-.026c.3-1.191 1.592-1.631 3.619-1.68.637-.023 1.368-.023 1.949-.023v-.169c0-1.106-.695-1.555-1.912-1.555-.9 0-1.556.375-1.631 1.012h-1.593c.17-1.612 1.874-2.024 3.357-2.024a3.235 3.235 0 013.012 1.267l.006.008-.545-1.05h1.762l2.568 5.155 2.568-5.156h1.553l-4.649 9.129h-1.682l1.337-2.549-2.912-5.473c.158.319.251.694.251 1.091l-.001.074v-.004 3.074c0 .432.012.862.05 1.256h-1.392a7.849 7.849 0 01-.057-.955v-.001a3.353 3.353 0 01-2.913 1.199l.014.001c-1.849 0-2.839-.975-2.839-2.118 0-.179.017-.354.049-.524l-.003.017a3.405 3.405 0 01-3.465 2.606h.008a3.508 3.508 0 01-2.747-1.159l-.003-.003a8.866 8.866 0 01-.054.957l.004-.04H8.516c.025-.487.05-1.068.05-1.556v-1.312H2.53a2.183 2.183 0 002.322 2.174h-.006a1.916 1.916 0 001.955-1.15l.005-.012h1.55c-.3 1.612-2.012 2.156-3.486 2.156-2.678.026-3.865-1.457-3.865-3.444a3.4 3.4 0 013.913-3.635l-.019-.002a3.297 3.297 0 013.673 3.517l.001-.011v.005z"></path>
    </svg>
  );
}

export default EbayIcon;
