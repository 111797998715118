import React from "react";

function MyspaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path d="M284.625 84.486C284.625 38.102 322.527.5 369.271.5c46.746 0 84.648 37.602 84.648 83.986 0 46.396-37.902 84.01-84.648 84.01-46.744 0-84.646-37.614-84.646-84.01zm84.646 104.096c-29.777 0-56.938 10.916-77.809 28.844-12.215-34.545-45.281-59.359-84.268-59.359-23.915 0-45.586 9.382-61.616 24.552-8.073-24.889-31.588-42.916-59.366-42.916-34.439 0-62.359 27.696-62.359 61.879h-.206v107.24h93.91v91.558h132.247V511.5H488.35V306.737c.002-65.258-53.319-118.155-119.079-118.155zm-162.076-45.586c35.088 0 63.52-28.22 63.52-63.039 0-34.807-28.432-63.027-63.52-63.027-35.068 0-63.513 28.22-63.513 63.027 0 34.819 28.444 63.039 63.513 63.039zM86.213 129.173c24.484 0 44.332-19.687 44.332-43.989 0-24.302-19.848-43.989-44.332-43.989-24.477 0-44.326 19.687-44.326 43.989 0 24.303 19.849 43.989 44.326 43.989z"></path>
    </svg>
  );
}

export default MyspaceIcon;
