import React from "react";

function WattpadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      ariaHidden="true"
      viewBox="0 0 14 14"
    >
      <path d="M2.71 11.377c-.536-.198-1.104-.927-1.364-1.754C1.07 8.751 1 8.143 1 6.651c0-1.2.016-1.46.122-1.946.288-1.31.826-1.882 1.716-1.822.637.043.972.316 1.165.95.088.289.118 1.715.043 2.029-.05.208-.036.191.443-.538.873-1.33 1.76-2.289 2.408-2.607.453-.223 1.018-.23 1.396-.018.531.298.785 1.004.739 2.056l-.028.643.353-.44c.8-.999 1.809-1.86 2.412-2.06.571-.19 1.048-.019 1.192.428.118.364-.006.656-.763 1.809-.78 1.185-.98 1.537-1.79 3.142-.669 1.323-.891 1.631-1.37 1.897-.266.147-.37.171-.742.171-.368 0-.474-.024-.701-.157-.315-.185-.521-.416-.717-.802-.181-.358-.254-.704-.303-1.445l-.04-.597-.221.402c-.122.22-.414.814-.649 1.318-.234.504-.519 1.07-.633 1.257-.254.418-.654.825-.97.988-.315.162-1.003.197-1.352.068z"></path>
    </svg>
  );
}

export default WattpadIcon;
