import React from "react";

function PinterestIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-220 -7399)">
          <g transform="translate(56 160)">
            <path d="M173.876 7239c-5.477 0-9.876 4.435-9.876 9.959 0 4.1 2.407 7.53 5.893 9.12 0-1.867-.013-1.626 1.41-7.699-.782-1.578-.174-4.183 1.577-4.183 2.43 0 .779 3.603.498 5.02-.249 1.088.581 1.925 1.577 1.925 1.909 0 3.153-2.427 3.153-5.271 0-2.176-1.493-3.767-4.066-3.767-4.575 0-5.735 5.096-4.149 6.695.399.604 0 .632 0 1.423-.277.836-2.49-.38-2.49-3.514 0-2.846 2.324-6.193 7.054-6.193 3.734 0 6.224 2.761 6.224 5.69 0 3.933-2.158 6.779-5.311 6.779-1.079 0-2.075-.586-2.407-1.256-.603 2.346-.725 3.535-1.66 4.854.913.25 1.826.418 2.822.418 5.477 0 9.875-4.435 9.875-9.958-.248-5.607-4.647-10.042-10.124-10.042"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PinterestIcon;
