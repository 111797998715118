import React from "react";

function FlipkartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="#002E71"
      version="1.1"
      viewBox="0 0 97.296 97.296"
      xmlSpace="preserve"
    >
      <path d="M68.052 42.539c2.183 0 4.21-.075 6.23.02 2.458.116 4.115-1.064 4.859-3.224.651-1.895 1.075-3.935 1.177-5.932.135-2.627-1.071-3.642-3.72-3.659-2.064-.013-4.129-.002-6.443-.002.733-3.377 1.209-6.47 2.092-9.443 1.433-4.832 4.964-7.257 10.029-7.301 1.216-.009 2.449-.021 3.644-.222 2.562-.433 3.392-1.578 3.026-4.095a41.123 41.123 0 00-.827-4.186C87.36 1.482 85.963.24 82.863.058c-5.025-.295-9.885.524-14.393 2.833-8.294 4.248-12.277 11.506-13.929 20.33-.396 2.12-.758 4.246-1.157 6.491-4.755 0-9.354-.018-13.953.009-2.313.013-2.889.772-2.368 3.051.56 2.442 1.226 4.862 1.72 7.318.357 1.773 1.269 2.5 3.093 2.44 2.968-.101 5.941-.028 9.173-.028-.086.669-.114.998-.172 1.321-1.856 10.404-3.647 20.819-5.601 31.203-1.054 5.602-4.049 7.893-9.822 7.9-6.915.014-13.829-.011-20.743.007-2.547.007-3.736.929-4.344 3.38-.684 2.761-1.297 5.539-1.937 8.312-.457 1.979-.022 2.599 2.078 2.603 9.828.018 19.662.186 29.484-.07 12.221-.317 19.691-6.93 21.738-18.715a9353.72 9353.72 0 013.438-19.617c.953-5.388 1.911-10.774 2.884-16.287z"></path>
    </svg>
  );
}

export default FlipkartIcon;
