import React from "react";

function RappiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 48 48"
    >
      <path
        d="M18.54 18.25a4.83 4.83 0 00-2.12.41c-4.82 2.18-5.8 7.34-11.87 3.12 2.64 9.09 10.45 7.94 12.62 7.87a9.75 9.75 0 006.88-3.4 9.75 9.75 0 006.88 3.4c2.21.07 10 1.22 12.62-7.87-6.1 4.26-7.07-.94-11.87-3.12-2.62-1.18-6 .36-7.59 2.47a7.57 7.57 0 00-5.48-2.88z"
        className="a"
      ></path>
    </svg>
  );
}

export default RappiIcon;
