import React from "react";

function QuoraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M18.167 26.807c-1.034.29-2.221.457-3.447.457-7.256 0-13.146-5.848-13.215-13.087v-.007C1.557 6.887 7.472 1.004 14.762 1.004s13.206 5.884 13.257 13.163v.108c0 4.118-1.952 7.78-4.982 10.111l-.03.022c.955 1.435 1.928 2.392 3.314 2.392h.039c1.161 0 2.11-.91 2.172-2.055v-.006h1.946a5.79 5.79 0 01-5.965 6.257h.009a7.022 7.022 0 01-6.35-4.115l-.018-.044zm-1.245-2.433c-1.038-2.043-2.256-4.108-4.634-4.108h-.046c-.461 0-.901.096-1.298.27l.021-.008-.807-1.611a7.006 7.006 0 014.627-1.517h-.012a6.737 6.737 0 016.091 3.455l.018.034c.724-1.784 1.144-3.853 1.144-6.02 0-.245-.005-.489-.016-.731l.001.034c0-6.976-2.179-10.556-7.275-10.556-5.021 0-7.194 3.58-7.194 10.556 0 6.939 2.173 10.483 7.194 10.483l.12.001c.736 0 1.447-.103 2.121-.295l-.054.013z"></path>
    </svg>
  );
}

export default QuoraIcon;
