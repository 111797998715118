import React from 'react'

import AboutmeIcon from '../../icons/aboutme'
import AdobeIcon from '../../icons/adobe'
import AmazonIcon from '../../icons/amazon'
import AppleIcon from '../../icons/apple'
import ArchiveorgIcon from '../../icons/archiveorg'
import AirbnbIcon from '../../icons/aribnb'
import AtlassianIcon from '../../icons/atlassian'
import BitmojiIcon from '../../icons/bitmoji'
import BookingIcon from '../../icons/booking'
import BukalapakIcon from '../../icons/bukalapak'
import CodecademyIcon from '../../icons/codecademy'
import DeliverooIcon from '../../icons/deliveroo'
import DiscordIcon from '../../icons/discord'
import DisneyplusIcon from '../../icons/disneyplus'
import DuolingoIcon from '../../icons/duolingo'
import EbayIcon from '../../icons/ebay'
import EnvatoIcon from '../../icons/envato'
import EventbriteIcon from '../../icons/eventbrite'
import EvernoteIcon from '../../icons/evernote'
import FacebookIcon from '../../icons/facebook'
import FirefoxIcon from '../../icons/firefox'
import FlickrIcon from '../../icons/flickr'
import FlipkartIcon from '../../icons/flipkart'
import FoursquareIcon from '../../icons/foursquare'
import FreelancerIcon from '../../icons/freelancer'
import GiphyIcon from '../../icons/giphy'
import GithubIcon from '../../icons/github'
import GoogleIcon from '../../icons/google'
import GravatarIcon from '../../icons/gravatar'
import HubspotIcon from '../../icons/hubspot'
import ImgurIcon from '../../icons/imgur'
import InstagramIcon from '../../icons/instagram'
import KakaoIcon from '../../icons/kakao'
import KomootIcon from '../../icons/komoot'
import LastfmIcon from '../../icons/lastfm'
import LazadaIcon from '../../icons/lazada'
import LinkedinIcon from '../../icons/linkedin'
import MailruIcon from '../../icons/mailru'
import MicrosoftIcon from '../../icons/microsoft'
import MyspaceIcon from '../../icons/myspace'
import NetflixIcon from '../../icons/netflix'
import NikeIcon from '../../icons/nike'
import OkIcon from '../../icons/ok'
import PatreonIcon from '../../icons/patreon'
import PinterestIcon from '../../icons/pinterest'
import PlurkIcon from '../../icons/plurk'
import QuoraIcon from '../../icons/quora'
import QzoneIcon from '../../icons/qzone'
import RamblerIcon from '../../icons/rambler'
import RappiIcon from '../../icons/rappi'
import ReplitIcon from '../../icons/replit'
import SamsungIcon from '../../icons/samsung'
import SkypeIcon from '../../icons/skype'
import SnapchatIcon from '../../icons/snapchat'
import SoundcloudIcon from '../../icons/soundcoud'
import SpotifyIcon from '../../icons/spotify'
import StravaIcon from '../../icons/strava'
import TaringaIcon from '../../icons/taringa'
import TokopediaIcon from '../../icons/tokopedia'
import TreehouseIcon from '../../icons/treehouse'
import TumblrIcon from '../../icons/tumblr'
import TwitterIcon from '../../icons/twitter'
import VenmoIcon from '../../icons/venmo'
import ViemoIcon from '../../icons/vimeo'
import VivinoIcon from '../../icons/vivino'
import VkontakteIcon from '../../icons/vkontakte'
import WattpadIcon from '../../icons/wattpad'
import WeiboIcon from '../../icons/weibo'
import WordpressIcon from '../../icons/wordpress'
import XingIcon from '../../icons/xing'
import YahooIcon from '../../icons/yahoo'
import YandexIcon from '../../icons/yandex'
import ZohoIcon from '../../icons/zoho'

import WhatsappIcon from '../../icons/whatsapp'
import TelegramIcon from '../../icons/telegram'
import ViberIcon from '../../icons/viber'

import TrueIcon from '../../icons/true'
import FalseIcon from '../../icons/false'


function EmailResult({ jsonResponse, apiIdLog, requestParams, lang }) {

  return (jsonResponse.email) ?
  (
    <div className="request_report">
        <div className="report_header">
        <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
        <h1 className="report_title">{jsonResponse.email}</h1>
        <div className="report_header_details">
            <div className="report_deliv_container">

            
            <span className={"report_deliverable_" + (jsonResponse.deliverable ? "true" : jsonResponse.deliverable === false ? "false" : "null")}>{jsonResponse.deliverable ? (lang == "spanish" ? "Entregable" : "Deliverable") : jsonResponse.deliverable == false ? (lang == "spanish" ? "No entregable" : "Does not exist") : (lang == "spanish" ? "Entrega indefinida" : "Undefined delivery") }</span>
            <span className={"report_deliverable_" + (jsonResponse.domain_details.disposable ? "true" : "false")}>{jsonResponse.domain_details.disposable ? (lang == "spanish" ? "Desechable" : "Disposable") : (lang == "spanish" ? "No desechable" : "Not disposable")}</span>
            <span className="report_deliv_expl">{lang == "spanish" ? "Verificado mediante una rápida comprobación SMTP-MX" : "Checked using a fast SMTP-MX check"}</span>
            </div>
            <div className="report_score">
            <span className={"report_value " + ( jsonResponse.score >= 8 ? "report_score_red" : jsonResponse.score >= 4 ? "report_score_orange" : "report_score_green")}>{jsonResponse.score}</span>
            <div className="report_score_label">
                <label>{lang == "spanish" ? "Scorce" : "Scorce"}</label>
                <span>{lang == "spanish" ? "Una puntuación de 0-100 que indica la probabilidad de que la dirección de correo electrónico del usuario sea fraudulenta. Un correo electrónico con una puntuación de 8 o más es riesgoso." : "A score of 0-100 indicating the likelihood that the user's email address is fraudulent. An email with a score of 8 or above is risky."}</span>
            </div>
            </div>
        </div>
        </div>

        <div className="report_informations">
        <h2>{lang == "spanish" ? "Detalles del dominio" : "Domain details"}</h2>
        
        <div className="report_info">
            <label>{lang == "spanish" ? "Dominio" : "Domain"}</label>
            <span>{jsonResponse.domain_details.domain}</span>
        </div>
        <div className="report_info">
            <label>TLD</label>
            <span>{jsonResponse.domain_details.tld}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Creado" : "Created"}</label>
            <span>{jsonResponse.domain_details.created}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Actualizado" : "Updated"}</label>
            <span>{jsonResponse.domain_details.updated}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Caduca" : "Expires"}</label>
            <span>{jsonResponse.domain_details.expires}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Nombre del registrador" : "Registrar name"}</label>
            <span>{jsonResponse.domain_details.registrar_name}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Registrado a" : "Registered to"}</label>
            <span>{jsonResponse.domain_details.registered_to}</span>
        </div>

        <div className="report_info_bool_flex">
            <div className="report_info_bool">
                { jsonResponse.domain_details.free ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "Gratuito" : "Free"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.custom ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "Personalizado" : "Custom"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.dmarc_enforced ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "DMARC Aplicado" : "DMARC Enforced"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.spf_strict ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "SPF Estricto" : "SPF Strict"}</label>
            </div>
            <div className="report_info_bool">
                { jsonResponse.domain_details.valid_mx ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "MX Válido" : "Valid MX"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.accept_all ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "Acepta Todos" : "Accept All"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.suspicious_tld ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "TLD Sospechoso" : "Suspicious TLD"}</label>
            </div>

            <div className="report_info_bool">
                { jsonResponse.domain_details.website_exists ? <TrueIcon /> : <FalseIcon />}
                <label>{lang == "spanish" ? "El sitio web existe" : "Website Exists"}</label>
            </div>
        </div>

        </div>

        <div className="report_informations">
        <h2>{lang == "spanish" ? "Detalles de la cuenta" : "Account details"}</h2>

        <div className="social_details">

            {
                [
                    "aboutme","adobe","amazon","apple","archiveorg","airbnb","atlassian","bitmoji",
                    "booking","bukalapak","codecademy","deliveroo","discord","disneyplus","duolingo",
                    "ebay","envato","eventbrite","evernote","facebook","firefox","flickr","flipkart",
                    "foursquare","freelancer","giphy","github","google","gravatar","hubspot","imgur",
                    "instagram","kakao","komoot","lastfm","lazada","linkedin","mailru","microsoft",
                    "myspace","netflix","nike","ok","patreon","pinterest","plurk","quora","qzone",
                    "rambler","rappi","replit","samsung","skype","snapchat","soundcoud","spotify",
                    "strava","taringa","tokopedia","treehouse","tumblr","twitter","venmo","vimeo",
                    "vivino","vkontakte","wattpad","weibo","wordpress","xing","yahoo","yandex","zoho"
                ]
                .map(social => (
                    <div className="social_detail">
                        <div className="social_img">
                            {
                                social == "aboutme" ? <AboutmeIcon /> :
                                social == "adobe" ? <AdobeIcon /> :
                                social == "amazon" ? <AmazonIcon /> :
                                social == "apple" ? <AppleIcon /> :
                                social == "archiveorg" ? <ArchiveorgIcon /> :
                                social == "airbnb" ? <AirbnbIcon /> :
                                social == "atlassian" ? <AtlassianIcon /> :
                                social == "bitmoji" ? <BitmojiIcon /> :
                                social == "booking" ? <BookingIcon /> :
                                social == "bukalapak" ? <BukalapakIcon /> :
                                social == "codecademy" ? <CodecademyIcon /> :
                                social == "deliveroo" ? <DeliverooIcon /> :
                                social == "discord" ? <DiscordIcon /> :
                                social == "disneyplus" ? <DisneyplusIcon /> :
                                social == "duolingo" ? <DuolingoIcon /> :
                                social == "ebay" ? <EbayIcon /> :
                                social == "envato" ? <EnvatoIcon /> :
                                social == "eventbrite" ? <EventbriteIcon /> :
                                social == "evernote" ? <EvernoteIcon /> :
                                social == "facebook" ? <FacebookIcon /> :
                                social == "firefox" ? <FirefoxIcon /> :
                                social == "flickr" ? <FlickrIcon /> :
                                social == "flipkart" ? <FlipkartIcon /> :
                                social == "foursquare" ? <FoursquareIcon /> :
                                social == "freelancer" ? <FreelancerIcon /> :
                                social == "giphy" ? <GiphyIcon /> :
                                social == "github" ? <GithubIcon /> :
                                social == "google" ? <GoogleIcon /> :
                                social == "gravatar" ? <GravatarIcon /> :
                                social == "hubspot" ? <HubspotIcon /> :
                                social == "imgur" ? <ImgurIcon /> :
                                social == "instagram" ? <InstagramIcon /> :
                                social == "kakao" ? <KakaoIcon /> :
                                social == "komoot" ? <KomootIcon /> :
                                social == "lastfm" ? <LastfmIcon /> :
                                social == "lazada" ? <LazadaIcon /> :
                                social == "linkedin" ? <LinkedinIcon /> :
                                social == "mailru" ? <MailruIcon /> :
                                social == "microsoft" ? <MicrosoftIcon /> :
                                social == "myspace" ? <MyspaceIcon /> :
                                social == "netflix" ? <NetflixIcon /> :
                                social == "nike" ? <NikeIcon /> :
                                social == "ok" ? <OkIcon /> :
                                social == "patreon" ? <PatreonIcon /> :
                                social == "pinterest" ? <PinterestIcon /> :
                                social == "plurk" ? <PlurkIcon /> :
                                social == "quora" ? <QuoraIcon /> :
                                social == "qzone" ? <QzoneIcon /> :
                                social == "rambler" ? <RamblerIcon /> :
                                social == "rappi" ? <RappiIcon /> :
                                social == "replit" ? <ReplitIcon /> :
                                social == "samsung" ? <SamsungIcon /> :
                                social == "skype" ? <SkypeIcon /> :
                                social == "snapchat" ? <SnapchatIcon /> :
                                social == "soundcoud" ? <SoundcloudIcon /> :
                                social == "spotify" ? <SpotifyIcon /> :
                                social == "strava" ? <StravaIcon /> :
                                social == "taringa" ? <TaringaIcon /> :
                                social == "tokopedia" ? <TokopediaIcon /> :
                                social == "treehouse" ? <TreehouseIcon /> :
                                social == "tumblr" ? <TumblrIcon /> :
                                social == "twitter" ? <TwitterIcon /> :
                                social == "venmo" ? <VenmoIcon /> :
                                social == "vimeo" ? <ViemoIcon /> :
                                social == "vivino" ? <VivinoIcon /> :
                                social == "vkontakte" ? <VkontakteIcon /> :
                                social == "wattpad" ? <WattpadIcon /> :
                                social == "weibo" ? <WeiboIcon /> :
                                social == "wordpress" ? <WordpressIcon /> :
                                social == "xing" ? <XingIcon /> :
                                social == "yahoo" ? <YahooIcon /> :
                                social == "yandex" ? <YandexIcon /> :
                                social == "whatsapp" ? <WhatsappIcon /> :
                                social == "telegram" ? <TelegramIcon /> :
                                social == "viber" ? <ViberIcon /> :
                                social == "zoho" ? <ZohoIcon/> : <></>
                            }
                        </div>
                        <div className="social_info">
                            <span className="social_name">{social}</span>
                            <span className={"social_value" + (
                                        jsonResponse.account_details[social]?.registered === true ? " social_value_true" :
                                        jsonResponse.account_details[social]?.registered === false ? " social_value_false" :
                                        " social_value_null"
                                    )
                                }>
                                {
                                    jsonResponse.account_details[social]?.registered === true ? (lang == "spanish" ? "Registrado" : "Registred") :
                                    jsonResponse.account_details[social]?.registered === false ? (lang == "spanish" ? "No registrado" : "Not registred") :
                                        (lang == "spanish" ? "Indefinido" : "Undefined")
                                }
                            </span>
                        </div>
                    </div>
                ))
            }

        </div>
        
        </div>

    </div>
  )
  :
  (
    <div className="request_report">
        <div className="report_header">
            <span className="report_id">#{apiIdLog}</span>
            <h1 className="report_title">{requestParams.filter(param => param.name == "email")[0]?.value}</h1>
            <div className="report_header_details">
                <div className="report_deliv_container">
                    <span className="report_deliverable_false">Error</span>
                </div>
            </div>
        </div>
    </div>
  )

}

export default EmailResult