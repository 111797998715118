import React from "react";

function HubspotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M24.219 10.573V6.781a2.927 2.927 0 001.688-2.641v-.089a2.93 2.93 0 00-2.922-2.922h-.089a2.931 2.931 0 00-2.927 2.922v.089a2.929 2.929 0 001.672 2.63l.016.01v3.802a8.27 8.27 0 00-3.958 1.745l.016-.01-10.438-8.13C8.22.666 3.626-1.589 1.418 1.312-.796 4.208 2.585 8.041 5.736 6.208l-.016.01 10.26 7.984a8.263 8.263 0 00-1.385 4.599c0 1.786.568 3.448 1.531 4.807l-.016-.026-3.125 3.12c-.25-.078-.51-.12-.771-.125h-.005c-2.411 0-3.625 2.922-1.917 4.63 1.708 1.703 4.63.495 4.63-1.917a2.752 2.752 0 00-.135-.797l.005.021 3.089-3.089a8.343 8.343 0 007.172 1.438 8.335 8.335 0 005.182-12.073 8.318 8.318 0 00-5.974-4.208l-.052-.01zm-1.287 12.505c-3.807-.01-5.703-4.615-3.005-7.302 2.693-2.688 7.292-.781 7.292 3.026v.005a4.271 4.271 0 01-4.276 4.271z"></path>
    </svg>
  );
}

export default HubspotIcon;
