import React from "react";

function QzoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M31.979 12.271c-.042-.135-.167-.297-.443-.344l-9.802-1.875s-.141-.031-.266-.094c-.12-.063-.167-.224-.167-.224s-4.708-8.458-4.839-8.708c-.13-.25-.323-.318-.464-.318s-.333.068-.464.318c-.13.25-4.839 8.708-4.839 8.708s-.047.161-.167.224c-.125.063-.266.094-.266.094S.736 11.875.46 11.922c-.276.052-.401.214-.443.344a.632.632 0 00.167.599l7.151 7.323s.094.104.156.229c.021.135.005.281.005.281s-1.583 9.656-1.625 9.932a.61.61 0 00.214.573c.109.083.307.141.557.016.255-.125 9.083-4.349 9.083-4.349s.13-.057.271-.078c.135-.026.266.078.266.078s8.833 4.224 9.083 4.349.453.068.563-.016a.63.63 0 00.214-.573l-1.245-7.568c1.172-.724 1.901-1.385 2.469-2.333-3.458 1.292-8.01 2.292-12.552 2.49-1.224.052-3.214.13-4.635-.021-.901-.094-1.557-.193-1.656-.583-.068-.286.073-.615.729-1.109 1.271-.958 2.542-1.917 3.813-2.87 1.714-1.292 4.745-3.297 4.745-3.646 0-.375-2.859-1.036-5.38-1.036-2.594 0-3.036.172-3.75.224-.651.042-1.026.005-1.073-.188-.078-.328.245-.516.786-.755.943-.422 2.479-.792 2.646-.833.26-.073 4.109-1.078 7.49-.714 1.755.182 4.328.891 4.328 1.698 0 .458-2.297 1.995-4.302 3.464-1.531 1.125-2.958 2.083-2.958 2.25 0 .458 4.714 1.656 8.922 1.349l.005-.031c.063-.12.156-.229.156-.229l7.151-7.318a.637.637 0 00.167-.599z"></path>
    </svg>
  );
}

export default QzoneIcon;
