import React from 'react'
import TrueIcon from '../../icons/true'
import FalseIcon from '../../icons/false'

function EmailResult({ jsonResponse, apiIdLog, requestParams, lang }) {

  return (jsonResponse.ip) ?
  (
    <div className="request_report">
        <div className="report_header">
        <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
        <h1 className="report_title">{jsonResponse.ip}</h1>
        <div className="report_header_details">
            <div className="report_deliv_container">
                <span className={"report_deliverable_" + (!jsonResponse.harmful ? "true" : "false")}>{!jsonResponse.harmful ? (lang == "spanish" ? "Seguro" : "Safe") : (lang == "spanish" ? "Nocivo" : "Harmful")}</span>
            </div>
            <div className="report_score">
            <span className={"report_value " + ( jsonResponse.score >= 8 ? "report_score_red" : jsonResponse.score >= 4 ? "report_score_orange" : "report_score_green")}>{jsonResponse.score}</span>
            <div className="report_score_label">
                <label>Scorce</label>
                <span>{lang == "spanish" ? "Una puntuación de 0-100 que indica la probabilidad de que la dirección de correo electrónico del usuario sea fraudulenta. Una IP con una puntuación de 8 o más es riesgosa." : "A score of 0-100 indicating the likelihood that the user's email address is fraudulent. An ip with a score of 8 or above is risky."}</span>
            </div>
            </div>
        </div>
        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Detalles de la dirección IP privada" : "Private IP Address details"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "País" : "Country"}</label>
                <span>{jsonResponse.country}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Estado/Provincia" : "State/Province"}</label>
                <span>{jsonResponse.state_prov}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Ciudad" : "City"}</label>
                <span>{jsonResponse.city}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Diferencia de huso horario" : "Timezone offset"}</label>
                <span>{jsonResponse.timezone_offset}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Tipo" : "Type"}</label>
                <span>{jsonResponse.type}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Longitud" : "Longitude"}</label>
                <span>{jsonResponse.longitude}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Latitud" : "Latitude"}</label>
                <span>{jsonResponse.latitude}</span>
            </div>
            <div className="report_info">
                <label>{lang == "spanish" ? "Nombre del ISP" : "ISP Name"}</label>
                <span>{jsonResponse.isp_name}</span>
            </div>
        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Atributos de Seguridad de la Red" : "Network Security Attributes"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Número de spam" : "Spam number"}</label>
                <span>{jsonResponse.spam_number}</span>
            </div>
            <br/>
            <div className="report_info_bool_flex">
                <div className="report_info_bool">
                    { jsonResponse.tor ? <TrueIcon /> : <FalseIcon />}
                    <label>{lang == "spanish" ? "Tor" : "Tor"}</label>
                </div>

                <div className="report_info_bool">
                    { jsonResponse.vpn ? <TrueIcon /> : <FalseIcon />}
                    <label>{lang == "spanish" ? "VPN" : "VPN"}</label>
                </div>

                <div className="report_info_bool">
                    { jsonResponse.web_proxy ? <TrueIcon /> : <FalseIcon />}
                    <label>{lang == "spanish" ? "Proxy web" : "Web proxy"}</label>
                </div>

                <div className="report_info_bool">
                    { jsonResponse.public_proxy ? <TrueIcon /> : <FalseIcon />}
                    <label>{lang == "spanish" ? "Proxy público" : "Public proxy"}</label>
                </div>
            </div>

        </div>


    </div>
  )
  :
  (
    <div className="request_report">
        <div className="report_header">
            <span className="report_id">#{apiIdLog}</span>
            <h1 className="report_title">{requestParams.filter(param => param.name == "ip")[0]?.value}</h1>
            <div className="report_header_details">
                <div className="report_deliv_container">
                    <span className="report_deliverable_false">Error</span>
                </div>
            </div>
        </div>
    </div>
  )

}

export default EmailResult