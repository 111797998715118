import React from "react";

function MicrosoftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-220 -7519)">
          <g transform="translate(56 160)">
            <path d="M174 7379h10v-9h-10v9zm-10 0h9v-9h-9v9zm10-10h10v-10h-10v10zm-10 0h9v-10h-9v10z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MicrosoftIcon;
