import React from "react";

function AmazonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path
        fill="#002E71"
        fillRule="evenodd"
        d="M293.596 233.97c0 26.322.627 48.264-12.651 71.65-10.724 19.022-27.791 30.698-46.749 30.698-25.905 0-41.069-19.73-41.069-48.979 0-57.525 51.607-67.983 100.469-67.983v14.614zm68.105 164.685c-4.48 4.005-10.934 4.283-15.971 1.567-22.446-18.64-26.462-27.263-38.718-45.009-37.07 37.767-63.335 49.094-111.356 49.094-56.871 0-101.09-35.085-101.09-105.269 0-54.833 29.688-92.112 72.023-110.394 36.647-16.086 87.836-19.004 127.006-23.397v-8.774c0-16.074 1.253-35.091-8.218-48.979-8.217-12.43-24.013-17.542-37.905-17.542-25.76 0-48.67 13.196-54.288 40.552-1.178 6.094-5.612 12.11-11.745 12.425l-65.459-7.092c-5.524-1.241-11.676-5.682-10.074-14.119C120.942 42.297 192.668 18.3 256.943 18.3c32.857 0 75.823 8.774 101.729 33.63 32.857 30.71 29.7 71.65 29.7 116.248v105.223c0 31.65 13.138 45.543 25.487 62.615 4.317 6.128 5.292 13.44-.209 17.92-13.8 11.571-38.324 32.869-51.811 44.87l-.138-.151zm92.56 18.722c-62.721 26.602-130.884 39.461-192.884 39.461-91.933 0-180.924-25.209-252.882-67.096-6.302-3.668-10.968 2.797-5.733 7.532 66.702 60.236 154.845 96.425 252.732 96.425 69.846 0 150.949-21.971 206.903-63.254 9.249-6.847 1.323-17.084-8.136-13.068zm16.701 50.278c-2.043 5.106 2.345 7.172 6.964 3.296 30.014-25.116 37.767-77.716 31.615-85.317-6.093-7.532-58.565-14.021-90.599 8.461-4.921 3.481-4.062 8.24 1.394 7.59 18.036-2.17 58.182-6.986 65.343 2.183 7.149 9.168-7.962 46.911-14.717 63.787z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AmazonIcon;
