import React, {useState, useEffect} from "react";
import axios from "axios";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";

import ReactGA from "react-ga";

import ApiList from "./components/api_list/ApiList";
import ApiRequest from "./components/api_request/ApiRequest";
import ApiResult from "./components/api_result/ApiResult";
import ApiLog from "./components/api_log/ApiLog";
import Demo from "./components/demo/Demo";
import Users from "./components/users/Users";


//const TRACKING_ID = "UA-195329365-1";
const TRACKING_ID = "UA-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  let navigate = useNavigate();

  const [loadLogRequest, setLoadLogRequest] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState({userId: "", username: "", userKey: ""});
  const [lang, setLang] = useState();

  const [methodsList, setMethodsList] = useState([]);
  const [errorMethods, setErrorMethods] = useState("loading");

  useEffect(() => {
    // language
    const language = localStorage.getItem("lang");
    if (language) {
      setLang(language);
    } else {
      localStorage.setItem("lang", "spanish");
      setLang("spanish");
    }

    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setIsLogged(true);
      setUser(JSON.parse(loggedInUser));


      try {
        const clientId = { clientId: JSON.parse(localStorage.getItem("user")).userId };
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/available-endpoints/neo`,
          clientId
        )
          .then(res => {
            if (res.data.err){
              setErrorMethods(res.data.err);
            } else if (res.data) {
              console.log(res.data);
              setMethodsList(
                res.data.map(endpoint => {
                  endpoint.Country = endpoint.Country.split(",")
                  .sort().filter(function(item, pos, ary) {
                    return !pos || item != ary[pos - 1];
                  })
                  return endpoint;
                })
              );
            } 
          })
  
      } catch (error) {
        setErrorMethods({errorMethods : "An error occurred, please try again later"});
      }  
      
    } else {
      setIsLogged(false);
      setMethodsList([
        {EndPointId: "phone", Name: "Validar Número de Teléfono", NameEnglish: "Validate Phone Number", Country: ["México"]},
        {EndPointId: "ip", Name: "Dirección IP", NameEnglish: "IP Address", Country: ["México"]},
        {EndPointId: "curp", Name: "Validación de CURP", NameEnglish: "Validation of CURP", Country: ["México"]},
        {EndPointId: "email", Name: "Comprobación completa del correo electrónico", NameEnglish: "Full Email Check", Country: ["México"]},
        {EndPointId: "nss-imss", Name: "Validar INE/IFE", NameEnglish: "Validate INE/IFE", Country: ["México"]}
      ]);
    }
    setLoadLogRequest(false);
  }, []);

  const logout = async () => {
    localStorage.clear();
    setUser({userId: "", username: "", userKey: ""});
    localStorage.setItem("lang", lang);
    setIsLogged(false);
    navigate("/");
    window.location.reload(false);
  }

  const changeLanguage =(language) => {
    localStorage.setItem("lang", language);
    setLang(language);
  }  

    return (
      loadLogRequest 
      ?
      <p>LOADING</p>
      :
      isLogged
      ?
      <> 
      <Routes>
        <Route path="/api-log/:endPointId" element={<ApiLog isLogged={true} methodsList={methodsList} user={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path="/api-request/:endPointId" element={<ApiRequest isLogged={true} methodsList={methodsList} user={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path="/api-result/:apiIdLog/:endPointType" element={<ApiResult isLogged={true} methodsList={methodsList} user={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path="/users" element={<Users  methodsList={methodsList} isLogged={true} user={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path="/" element={<ApiList methodsList={methodsList} isLogged={true} user={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />
        {/* <Route path='/users' element={<Users isLogged={true} lang={lang} changeLanguage={changeLanguage}/>} /> */}
      </Routes> </>
      :
      <Routes>
        <Route path="/api-result/:apiIdLog" element={<ApiResult setIsLogged={setIsLogged} setUser={setUser} isLogged={false} lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path='/demo-request/:endPointReference' element={<Demo setIsLogged={setIsLogged} setUser={setUser}  lang={lang} changeLanguage={changeLanguage}/>} />
        <Route path="/" element={<ApiList methodsList={methodsList} setIsLogged={setIsLogged} setUser={setUser} isLogged={false} userId={user} logout={() => logout} lang={lang} changeLanguage={changeLanguage}/>} />  
      </Routes>
    );

}

export default App;
