import React from "react";

function ReplitIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#002E71"
        d="M7 5.5A1.5 1.5 0 018.5 4h7A1.5 1.5 0 0117 5.5V12H8.5A1.5 1.5 0 017 10.5v-5zM17 12h8.5a1.5 1.5 0 011.5 1.5v5a1.5 1.5 0 01-1.5 1.5H17v-8zM7 21.5A1.5 1.5 0 018.5 20H17v6.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 017 26.5v-5z"
      ></path>
    </svg>
  );
}

export default ReplitIcon;
