import React from "react";

function OkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-5 0 24 24"
    >
      <path d="M9.456 17.441a11.592 11.592 0 003.647-1.518l-.047.028a1.817 1.817 0 00-1.943-3.069l.008-.005c-1.184.751-2.625 1.196-4.17 1.196s-2.987-.446-4.202-1.215l.032.019a1.815 1.815 0 00-2.496.558l-.004.007v.005a1.812 1.812 0 00.56 2.496l.007.004A11.354 11.354 0 004.37 17.42l.078.015L.979 20.9a1.796 1.796 0 00-.538 1.284c0 .489.195.932.51 1.256l.03.03c.326.327.777.529 1.275.529s.949-.202 1.275-.529l3.421-3.404 3.4 3.406a1.814 1.814 0 102.566-2.565l-3.461-3.466zm-2.505-5.052a6.194 6.194 0 10-6.2-6.194v.006a6.2 6.2 0 006.2 6.188zm0-8.758A2.565 2.565 0 114.386 6.2v-.002a2.567 2.567 0 012.565-2.567z"></path>
    </svg>
  );
}

export default OkIcon;
