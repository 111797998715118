import React from "react";

function BitmojiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      data-name="Layer 1"
      viewBox="0 0 192 192"
    >
      <path fill="none" d="M0 0h192v192H0z"></path>
      <path
        fill="none"
        stroke="#002E71"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M158.63 140V22H33.37v118H90v30l20-30h48.63z"
      ></path>
      <path
        fill="none"
        stroke="#002E71"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M65 92a31.16 31.16 0 0062 0z"
      ></path>
      <ellipse cx="118" cy="60" rx="10" ry="12.73"></ellipse>
      <path
        fill="none"
        stroke="#002E71"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M71 64a8.07 8.07 0 0116 0 17 17 0 00-16 0z"
      ></path>
    </svg>
  );
}

export default BitmojiIcon;
