import React from "react";

function TrueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#18871c"
      viewBox="0 -960 960 960"
    >
      <path d="M424-312l282-282-56-56-226 226-114-114-56 56 170 170zM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200z"></path>
    </svg>
  );
}

export default TrueIcon;
