import React from "react";

function PlurkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M17.203 2.542A13.404 13.404 0 006.698 7.485c-.13.156-.255.313-.38.474h.005c.313-.266.63-.464.958-.594l.078-.031a2.163 2.163 0 011.198-.12c1.922.385 2.922 3.479 2.234 6.911s-2.802 5.901-4.719 5.521c-1.349-.271-2.24-1.875-2.417-3.99v-.01.073a9.4 9.4 0 000 .531v.177c.005.198.021.396.031.589.01.089.016.182.026.271a13.44 13.44 0 001.281 4.589.392.392 0 01-.104.484l-.37.302c-.38.313-.531.823-.385 1.292 0 .01.005.016.01.021.021.068.047.13.078.193l.068-.052.302-.234c.37-.281.792.276.422.557l-.323.245-.052.036 1.568 1.932a1.218 1.218 0 001.703.177l.344-.281a.392.392 0 01.5 0 13.368 13.368 0 008.703 2.906 13.364 13.364 0 007.427-2.474.401.401 0 01.505.036l.219.208a1.216 1.216 0 001.573.078l-.005-.005-.281-.266c-.313-.318.141-.797.479-.505l.297.276 1.682-1.766a1.208 1.208 0 00-.047-1.708l-.349-.333a.397.397 0 01-.073-.479 13.422 13.422 0 001.474-8.896c.401-.37 1.135-1.099 1.422-1.724.307-.667.391-1.359-.427-1.057-.635.24-1.125.391-1.719.255-1.885-4.792-6.458-8.255-11.797-8.531a8.435 8.435 0 00-.635-.021zM3.021 8.328a1.638 1.638 0 00-1.443 2.354 1.637 1.637 0 00-1.49 2.161 1.64 1.64 0 002.495.818c.234-.167.406-.385.526-.625.25.453.615.833 1.036 1.099-.005.266 0 .531.026.797.135 1.641.823 2.88 1.865 3.089 1.484.297 3.12-1.615 3.651-4.266.531-2.656-.24-5.047-1.724-5.344-.583-.115-1.182.109-1.734.578-.599.51-1.135 1.318-1.521 2.307-.01-.005-.026-.005-.036-.01-.188-.052-.286-.177-.417-.203.979-1.063.208-2.781-1.234-2.755zM6.839 9.99c.068 0 .141.005.208.021.969.188 1.474 1.745 1.13 3.474-.349 1.729-1.411 2.974-2.38 2.781-.656-.13-1.099-.896-1.208-1.911.245.094.495.151.75.161.703.031 1.161-.203 1.521-.682.458-.62.109-2.339-.5-2.563-.474-.172-.755.036-1.177.068.214-.396.464-.724.74-.958.292-.245.604-.391.917-.396z"></path>
    </svg>
  );
}

export default PlurkIcon;
