import React from "react";

function FlickrIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-5.5 0 32 32"
    >
      <path d="M2.625 5.594h15.563a2.6 2.6 0 012.625 2.625v15.563c0 .781-.313 1.375-.781 1.844-.438.438-1.125.781-1.844.781H2.625A2.6 2.6 0 010 23.782V8.219C0 7.5.344 6.813.781 6.375c.469-.469 1.063-.781 1.844-.781zm3.469 13.844c1.938 0 3.531-1.563 3.531-3.5s-1.594-3.531-3.531-3.531c-1.906 0-3.531 1.594-3.531 3.531s1.625 3.5 3.531 3.5zm8.625 0c1.906 0 3.531-1.563 3.531-3.5s-1.625-3.531-3.531-3.531c-1.938 0-3.563 1.594-3.563 3.531s1.625 3.5 3.563 3.5z"></path>
    </svg>
  );
}

export default FlickrIcon;
