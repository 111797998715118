import React from "react";

function AtlassianIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0H24V24H0z"></path>
      <path d="M7.93 11.24a.58.58 0 00-.8-.11.68.68 0 00-.17.21l-4.9 9.81a.59.59 0 00.26.79.43.43 0 00.27.06h6.82a.59.59 0 00.53-.32c1.47-3.05.56-7.68-2-10.44m3.6-8.93a13 13 0 00-.76 12.78l3.29 6.59a.6.6 0 00.53.32h6.82a.58.58 0 00.59-.59.4.4 0 00-.06-.26S12.76 2.77 12.5 2.31a.52.52 0 00-.72-.25.51.51 0 00-.25.25z"></path>
    </svg>
  );
}

export default AtlassianIcon;
