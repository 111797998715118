import React from "react";

function TreehouseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-1 0 24 24"
    >
      <path d="M19.517 4.338c-.797-.448-2.069.275-2.84 1.617L15.276 8.39a3.024 3.024 0 00-.338 1.399c0 .653.204 1.259.552 1.756l-.007-.01.041.061a18.593 18.593 0 001.803 2.095 1.592 1.592 0 11-2.732.889l-.001.008a1.927 1.927 0 00-.567-1.472c-.5-.557-1.523.495-1.854 1.592l-.016.06a7.933 7.933 0 00-.474 2.087l-.002.032c.037.059.071.119.099.18a1.792 1.792 0 11-2.431-.733l.009-.005.12-.06c.183-.294.327-.634.414-.997l.005-.024.297-.976a.922.922 0 00.049-.156l.001-.006.89-3.155-.947 1.939c-.111-.359-.297-.376-.701-.09-.24.181-.646.525-.841.662a3.486 3.486 0 00-.833 1.077l-.009.02c-.095.2-.233.366-.402.492l-.003.002a1.555 1.555 0 01-2.207-.221l-.002-.003a1.574 1.574 0 01.892-2.523l.01-.002a12.972 12.972 0 002.604-1.527l-.033.024c.141-.092.264-.19.377-.298l-.001.001 2.176-1.652-1.897 1.013a2.204 2.204 0 00-.433.018l.011-.001a3.76 3.76 0 00-1.57.356l.023-.01a.999.999 0 01-.283.267l-.004.002a1.406 1.406 0 11-1.64-2.284l.005-.003c.239-.168.535-.269.855-.271.559.089 1.204.14 1.861.14.387 0 .77-.018 1.148-.052l-.049.004.285-.045a4.798 4.798 0 002.979-2.143l.012-.02 1.171-2.044c.646-1.127.601-2.358-.099-2.77L12.275.262c-.34-.17-.74-.269-1.164-.269s-.824.099-1.18.276l.015-.007L1.18 5.178A2.467 2.467 0 00-.007 7.155v9.644a2.545 2.545 0 001.155 1.992l.01.006 8.754 4.929c.34.171.74.271 1.164.271s.825-.1 1.179-.278l-.015.007 8.73-4.929a2.544 2.544 0 001.171-1.991V7.145a2.56 2.56 0 00-1.147-1.992l-.01-.006-1.444-.814z"></path>
    </svg>
  );
}

export default TreehouseIcon;
