import React from "react";

function TumblrIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-4 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-184 -7399)">
          <g transform="translate(56 160)">
            <path d="M139.001 7254.975l.999 2.852c-.377.531-2.088 1.146-3.623 1.171-4.551.075-6.288-3.103-6.288-5.341V7247H128v-2.461c3.17-1.094 3.931-3.831 4.11-5.39a.162.162 0 01.151-.149h3.05v5h4.178v3h-4.178v6.349c0 .846.336 2.014 2.008 1.971.554-.013 1.294-.168 1.682-.345"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TumblrIcon;
