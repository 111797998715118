import React from "react";

function WeiboIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path d="M384.371 248.315c-7.201-2.152-12.138-3.623-8.368-13.075 8.155-20.531 9.004-38.238.152-50.875-16.589-23.695-61.975-22.421-113.999-.634 0-.034-16.336 7.145-12.158-5.814 8.002-25.728 6.797-47.273-5.658-59.715-28.221-28.243-103.279 1.069-167.652 65.412C28.488 231.822.5 282.913.5 327.092c0 84.509 108.366 135.879 214.383 135.879 138.971 0 231.422-80.745 231.422-144.85 0-38.731-32.626-60.716-61.934-69.806zM215.165 432.688C130.573 441.027 57.551 402.8 52.05 347.256c-5.491-55.531 58.654-107.325 143.236-115.684 84.603-8.375 157.626 29.868 163.113 85.387 5.481 55.558-58.629 107.365-143.234 115.729zm289.781-206.709v.074c-3.329 10.261-14.357 15.89-24.629 12.568-10.312-3.328-15.938-14.339-12.606-24.637l-.013-.009c10.217-31.602 3.742-67.651-20.093-94.081-23.873-26.423-59.043-36.527-91.548-29.627-10.571 2.26-20.977-4.495-23.22-15.049-2.267-10.554 4.474-20.961 15.038-23.22h.013c45.704-9.714 95.204 4.468 128.767 41.676v-.002c33.574 37.172 42.617 87.842 28.291 132.307zm-79.836-85.793l-.005-.006c16.376 18.119 20.748 42.795 13.747 64.456-2.854 8.862-12.349 13.69-21.2 10.856-8.851-2.87-13.695-12.372-10.842-21.213h-.013c3.438-10.59 1.287-22.664-6.702-31.533-7.996-8.84-19.799-12.199-30.683-9.893v-.013c-9.069 1.978-18.021-3.855-19.965-12.919-1.957-9.111 3.842-18.068 12.945-20.005 22.271-4.732 46.382 2.151 62.718 20.27zM223.621 274.308c-40.258-10.479-85.759 9.587-103.244 45.06-17.804 36.176-.586 76.332 40.079 89.464 42.134 13.583 91.786-7.238 109.056-46.281 17.027-38.175-4.235-77.475-45.891-88.243zm-30.743 92.39c-8.181 13.052-25.704 18.771-38.904 12.745-13.006-5.917-16.84-21.086-8.657-33.813 8.083-12.673 25.013-18.327 38.117-12.832 13.248 5.646 17.484 20.708 9.444 33.9zm26.955-34.604c-2.961 5.07-9.501 7.498-14.62 5.389-5.045-2.071-6.624-7.73-3.761-12.707 2.949-4.943 9.239-7.358 14.263-5.369 5.107 1.87 6.941 7.597 4.118 12.687z"></path>
    </svg>
  );
}

export default WeiboIcon;
