import React from "react";

function MailruIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path d="M448.101 101.298C364.357 13.841 225.482-3.505 120.149 59.183-17.548 141.116-20.307 299.247 31.604 389.13c76.845 133.102 241.338 135.638 300.438 110.471 39.112-16.63 21.719-67.538-15.944-52.347-74.367 30.039-199.509-.063-243.232-100.993-40.126-92.536 1.178-191.062 65.712-235.571 60.714-41.85 174.872-54.118 259.952 20.344 91.283 79.87 52.532 217.721 20.138 251.012-8.305 8.475-23.268 1.655-21.931-11.557l-.096-201.744c0-5.683-3.895-23.618-22.849-23.618l-3.067.016c-12.13 0-20.858 9.785-20.858 22.758v-.101c-26.95-27.03-63.675-44.292-102.729-44.292-77.163 0-145.057 68.684-145.057 146.612 0 77.163 67.894 145.04 145.057 145.04 39.054 0 74.897-15.595 101.832-42.322l.403 14.406c-.366 44.068 57.084 73.491 95.767 43.251 85.387-66.549 90.492-237.76 2.961-329.197zm-201.478 266.32c-51.418 0-90.631-36.932-90.631-95.379 0-52.214 32.41-98.177 90.854-98.177h1.921c50.638 0 92.578 33.976 95.48 90.472l.127 11.414c-2.728 49.677-40.423 91.67-97.751 91.67z"></path>
    </svg>
  );
}

export default MailruIcon;
