import React from "react";

function XingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 512 512"
    >
      <path
        fillRule="evenodd"
        d="M309.215 304.749c30.414 57.874 62.227 114.961 94.801 173.782 4.402 7.997 11.826 19.137 8.67 26.073-4.242 9.332-25.275 5.539-41.855 5.539-19.523 0-36.902 3.53-49.764-.799-13.125-4.416-25.002-36.989-31.6-48.979-27.934-50.773-57.736-105.254-85.319-154.831 47.294-86.353 103.108-180.979 152.448-270.165C364.168 21.708 370.344 1.598 388.209.6c8.471-.46 25.387.8 42.666.8 14.32 0 36.428-2.108 40.295 3.941 6.6 10.367-10.842 30.466-15.807 39.51-48.205 87.589-98.392 173.146-146.148 259.898zM125.953 357.67c15.657-1.908 23.653-23.777 30.789-36.341 22.631-39.746 45.697-80.715 66.369-117.705-12.774-23.728-29.766-51.385-44.249-76.624-6.25-10.891-10.979-25.087-25.263-25.275h-75.85c-4.129.586-7.846 1.635-8.682 5.527-2.046 12.438 5.625 19.486 9.48 26.073 8.97 15.407 17.978 29.866 27.646 46.608 3.094 5.327 12.874 18.938 12.625 24.489-.15 3.718-7.598 12.638-10.254 17.378-21.607 38.586-39.334 70.735-60.83 109.009-3.194 5.689-10.267 12.301-7.896 20.534 1.023 3.605 6.749 6.637 9.469 6.325 25.546.002 51.096.002 76.646.002z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default XingIcon;
