import React from "react";

function ViberIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#002E71"
        d="M13 4a1 1 0 100 2c1.273 0 2.256.42 2.918 1.082C16.58 7.744 17 8.727 17 10a1 1 0 102 0c0-1.727-.58-3.244-1.668-4.332C16.244 4.58 14.727 4 13 4zM5.014 8.006c.114-.904 1.289-2.132 2.22-1.996V6.01c.779.148 1.418 1.32 1.84 2.093.069.127.132.244.19.343.286.509.1 1.025-.167 1.243L9 9.765c-.356.283-.819.65-.71 1.018C8.5 11.5 11 14 12.23 14.711c.376.217.669-.205.92-.567.059-.086.116-.168.17-.237.21-.28.727-.447 1.235-.171.759.442 1.474.956 2.135 1.534.33.276.408.684.179 1.115-.403.76-1.569 1.76-2.415 1.557C12.976 17.587 7 15.27 5.08 8.558c-.1-.296-.084-.42-.07-.528l.004-.024z"
      ></path>
      <path
        fill="#002E71"
        d="M13 7a1 1 0 100 2c.175 0 .433.097.668.332.235.235.332.493.332.668a1 1 0 102 0c0-.825-.402-1.567-.918-2.082C14.567 7.403 13.825 7 13 7z"
      ></path>
      <path
        fill="#002E71"
        fillRule="evenodd"
        d="M7.517 23.831c.029.016.058.03.088.043.543.233 1.213-.288 2.552-1.33L11.5 21.5c2.688.089 5.014-.264 6.812-.893a7 7 0 004.295-4.295c.97-2.771.97-7.853 0-10.624a7 7 0 00-4.295-4.295c-3.202-1.12-9.482-1.1-12.624 0a7 7 0 00-4.295 4.295c-.97 2.77-.97 7.853 0 10.624a7 7 0 004.295 4.295c.395.138.836.29 1.312.393 0 1.697 0 2.546.517 2.831zM9 20.911v-1.12a.498.498 0 00-.394-.488c-.762-.163-1.52-.325-2.257-.584a5 5 0 01-3.068-3.068c-.382-1.091-.615-2.806-.615-4.651 0-1.845.233-3.56.615-4.652A5 5 0 016.349 3.28c1.267-.443 3.368-.717 5.63-.72 2.263-.002 4.377.267 5.672.72a5 5 0 013.068 3.068c.382 1.092.615 2.807.615 4.652 0 1.845-.233 3.56-.615 4.651a5 5 0 01-3.068 3.068c-2.131.746-4.392.83-6.627.764a.503.503 0 00-.324.105L9 20.911z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ViberIcon;
