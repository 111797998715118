import React from "react";

function ViemoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 -1.5 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-60 -7560)">
          <g transform="translate(56 160)">
            <path d="M23.99 7403.933c-.09 1.912-1.449 4.532-4.079 7.858-2.719 3.473-5.02 5.209-6.902 5.209-1.166 0-2.152-1.06-2.958-3.178l-1.614-5.827c-.598-2.12-1.24-3.18-1.927-3.18-.15 0-.673.31-1.569.928L4 7404.551a262.93 262.93 0 002.913-2.56c1.315-1.12 2.301-1.708 2.958-1.767 1.554-.146 2.51.898 2.869 3.134.388 2.415.657 3.915.807 4.504.448 2.001.941 3.002 1.479 3.002.418 0 1.046-.647 1.882-1.943.837-1.295 1.285-2.28 1.345-2.957.119-1.12-.329-1.678-1.345-1.678-.478 0-.971.103-1.479.309.986-3.15 2.869-4.68 5.648-4.591 2.061.059 3.033 1.368 2.913 3.929z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ViemoIcon;
