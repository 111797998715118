import React from "react";

function FreelancerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M9.832 16.769l4.128 4.434 4.552-4.461 1.412-8.313-5.547-.285zm-.509-.515l4.37-8.15-12.689-.689zM6.158 4.849l1.071 2.199 5.916.367zm11.614-.001L14.784 7.54l5.026.187zM7.881 27.152l5.591-5.466-3.364-3.611zM18.619 4.848l2.042 2.864 10.334-2.864z"></path>
    </svg>
  );
}

export default FreelancerIcon;
