import React from "react";

function ArchiveorgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="86"
      viewBox="0 0 76 86"
    >
      <path
        fill="#2c2c2c"
        fillRule="evenodd"
        d="M76 82v4H0v-4zm-3-6v5H3v-5zM10.33 22l.835.422.417 6.743L12 40.123v10.538l-.418 13.137-.07 8.85L10.33 73H6.157l-1.252-.352-.487-8.85L4 50.73V40.194l.418-11.029.382-6.78.87-.385zm60 0l.836.422.417 6.743L72 40.123v10.538l-.417 13.137-.069 8.85-1.183.352h-4.174l-1.252-.352-.488-8.85L64 50.73V40.194l.417-11.029.383-6.78.87-.385zm-42 0l.835.422.417 6.743L30 40.123v10.538l-.418 13.137-.068 8.85L28.33 73h-4.174l-1.253-.352-.486-8.85L22 50.73V40.194l.417-11.029.383-6.78.869-.385zm23 0l.835.422.417 6.743L53 40.123v10.538l-.418 13.137-.068 8.85L51.33 73h-4.174l-1.252-.352-.487-8.85L45 50.73V40.194l.417-11.029.383-6.78.87-.385zM73 13v7H3v-7zM37.28 0L74 8.409 72.528 11H2.2L0 8.899z"
      ></path>
    </svg>
  );
}

export default ArchiveorgIcon;
