import React from 'react'
import TrueIcon from '../../icons/true'
import FalseIcon from '../../icons/false'

function CurpResult({ jsonResponse, apiIdLog, requestParams, lang, setSwitchLayout }) {

    const numEntidadRegValues = {
        "01":"Aguascalientes",
        "02":"Baja California",
        "03":"Baja California Sur",
        "04":"Campeche",
        "05":"Coahuila",
        "06":"Colima",
        "07":"Chiapas",
        "08":"Chihuahua",
        "09":"Ciudad de México",
        "1":"Aguascalientes",
        "2":"Baja California",
        "3":"Baja California Sur",
        "4":"Campeche",
        "5":"Coahuila",
        "6":"Colima",
        "7":"Chiapas",
        "8":"Chihuahua",
        "9":"Ciudad de México",
        "10":"Durango",
        "11":"Guanajuato",
        "12":"Guerrero",
        "13":"Hidalgo",
        "14":"Jalisco",
        "15":"México (Estado de)",
        "16":"Michoacán",
        "17":"Morelos",
        "18":"Nayarit",
        "19":"Nuevo León",
        "20":"Oaxaca",
        "21":"Puebla",
        "22":"Querétaro",
        "23":"Quintana Roo",
        "24":"San Luis Potosí",
        "25":"Sinaloa",
        "26":"Sonora",
        "27":"Tabasco",
        "28":"Tamaulipas",
        "29":"Tlaxcala",
        "30":"Veracruz",
        "31":"Yucatán",
        "32":"Zacatecas",
        "99":"Nacido en el Extranjero"
    }

    const cveEntidadNacValue = {
        "AS":"Aguascalientes",
        "BC":"Baja California",
        "BS":"Baja California Sur",
        "CC":"Campeche",
        "CL":"Coahuila",
        "CM":"Colima",
        "CS":"Chiapas",
        "CH":"Chihuahua",
        "DF":"Distrito Federal",
        "DG":"Durango",
        "GT":"Guanajuato",
        "GR":"Guerrero",
        "HG":"Hidalgo",
        "JC":"Jalisco",
        "MC":"México (Estado de)",
        "MN":"Michoacán",
        "MS":"Morelos",
        "NT":"Nayarit",
        "NL":"Nuevo León",
        "OC":"Oaxaca",
        "PL":"Puebla",
        "QT":"Querétaro",
        "QR":"Quintana Roo",
        "SP":"San Luis Potosí",
        "SL":"Sinaloa",
        "SR":"Sonora",
        "TC":"Tabasco",
        "TS":"Tamaulipas",
        "TL":"Tlaxcala",
        "VZ":"Veracruz",
        "YN":"Yucatán",
        "ZS":"Zacatecas",
        "NE":"Nacido en el Extranjero (Born Abroad)"
    }

    const statusCurpValue = {
        "AN":"Normal registration",
        "AH":"Registration with homonymy",
        "RCC":"Registration change affecting CURP",
        "RCN":"Registration change not affecting CURP",
        "BAP":"Unsubscribed because apocryphal document",
        "BSU":"Unsubscribed without use",
        "BD":"Unsubscribed because of deceased",
        "BDM":"Administrative unsubscription",
        "BDP":"Unsubscribed because of adoption",
        "BJD":"Judicial unsubscription"
    }

    const statusCurpValueSpanish = {
        "AN": "Registro normal",
        "AH": "Registro con homonimia",
        "RCC": "Cambio de registro afectando CURP",
        "RCN": "Cambio de registro sin afectar CURP",
        "BAP": "Baja por documento apócrifo",
        "BSU": "Baja sin uso",
        "BD": "Baja por fallecimiento",
        "BDM": "Baja administrativa",
        "BDP": "Baja por adopción",
        "BJD": "Baja judicial"
    }

    const docProbatorioValues = {
        "1":"Birth certificate",
        "3":"Migration document",
        "4":"Naturalization Certificate",
        "7":"Certificate of mexican nationality",
        "8":"Procedure with the SEGOB"
    }
    const docProbatorioValuesSpanish = {
        "1": "Acta de nacimiento",
        "3": "Documento migratorio",
        "4": "Certificado de naturalización",
        "7": "Constancia de nacionalidad mexicana",
        "8": "Trámite con la SEGOB"
    }

  return (jsonResponse.apellidoMaterno) ?
  (
    <div className="request_report">
        <div className="report_header">
        <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
        <h1 className="report_title">{jsonResponse.curp}</h1>
        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Información Personal" : "Personal Information"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Nombres" : "Names"}</label>
                <span>{jsonResponse.nombre || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Primer Apellido" : "First Last Name"}</label>
                <span>{jsonResponse.apellidoPaterno || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Segundo Apellido" : "Second Last Name"}</label>
                <span>{jsonResponse.apellidoMaterno || "-"}</span>
            </div>
            
            <div className="report_info">
                <label>{lang == "spanish" ? "Género" : "Gender"}</label>
                <span>{jsonResponse.sexo || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Fecha de Nacimiento" : "Date of Birth"}</label>
                <span>{jsonResponse.fechaNacimiento || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "País de Nacimiento" : "Country of Birth"}</label>
                <span>{jsonResponse.paisNacimiento || "-"}</span>
            </div>

        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Detalles de Registro y Documentación" : "Registration and Document Details"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Prueba Documental" : "Documentary Evidence"}</label>
                <span>{jsonResponse.docProbatorio || "-"}</span>
                {
                    jsonResponse.docProbatorio &&
                    <span className="report_info_additional">({lang == "spanish" ? docProbatorioValuesSpanish[jsonResponse.docProbatorio] : docProbatorioValues[jsonResponse.docProbatorio]})</span>
                }
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Entidad de Registro" : "Registration Entity"}</label>
                <span>{jsonResponse.datosDocProbatorio.entidadRegistro || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Municipio de Registro" : "Registration Municipality"}</label>
                <span>{jsonResponse.datosDocProbatorio.municipioRegistro || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Año de Registro" : "Registration Year"}</label>
                <span>{jsonResponse.datosDocProbatorio.anioReg || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Número de Acta" : "Act Number"}</label>
                <span>{jsonResponse.datosDocProbatorio.numActa || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Clave de Municipio de Registro" : "Municipality Registration Key"}</label>
                <span>{jsonResponse.datosDocProbatorio.claveMunicipioRegistro || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Clave de Entidad de Registro" : "Entity Registration Key"}</label>
                <span>{jsonResponse.datosDocProbatorio.claveEntidadRegistro || "-"}</span>
                {
                    jsonResponse.datosDocProbatorio.claveEntidadRegistro &&
                    <span className="report_info_additional">({numEntidadRegValues[jsonResponse.datosDocProbatorio.claveEntidadRegistro]})</span>
                }
                
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Tomo" : "Volume"}</label>
                <span>{jsonResponse.datosDocProbatorio.tomo || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Folio" : "Folio"}</label>
                <span>{jsonResponse.datosDocProbatorio.foja || "-"}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Libro" : "Book"}</label>
                <span>{jsonResponse.datosDocProbatorio.libro || "-"}</span>
            </div>

        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Detalles Adicionales" : "Additional Details"}</h2>


            <div className="report_info">
                <label>{lang == "spanish" ? "Estado de CURP" : "CURP Status"}</label>
                <span>{jsonResponse.estatusCurp || "-"}</span>
                {
                    jsonResponse.estatusCurp &&
                    <span className="report_info_additional">({lang == "spanish" ? statusCurpValueSpanish[jsonResponse.estatusCurp] : statusCurpValue[jsonResponse.estatusCurp]})</span>
                }
            </div>
        </div>




    </div>
  )
  :
  (
    <div className="request_report">
        <div className="report_header">
            <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
            <h1 className="report_title">{requestParams.filter(param => param.name == "curp")[0]?.value}</h1>
            <div className="report_header_details">
                <div className="report_deliv_container">
                    <span className="report_deliverable_false">Error</span>
                    <span className="report_error_result" onClick={() => setSwitchLayout("table")}>{lang == "spanish" ? "Ver más detalles" : "See more details" }</span>
                </div>
            </div>
        </div>
    </div>
  )

}

export default CurpResult