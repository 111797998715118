import React from "react";

function RamblerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#002E71" d="M0 0h90v90H0z"></path>
        <path fill="#FFF" d="M54 14h8L37 76h-8z"></path>
      </g>
    </svg>
  );
}

export default RamblerIcon;
