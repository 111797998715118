import React from "react";

function AirbnbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-1 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-261 -7399)">
          <g transform="translate(56 160)">
            <path d="M219.77 7256.585c-1.545.902-3.179-.308-4.389-1.63 1.103-1.494 2.156-3.308 2.213-5.163.048-1.764-.904-3.116-2.31-3.629a3.725 3.725 0 00-2.332-.077c-1.4.413-2.405 1.628-2.525 3.2-.165 1.984 1.003 4.072 2.2 5.669-.707.782-1.664 1.638-2.7 1.87-1.8.424-3.336-1.281-2.73-3.141.31-.948 5.12-11.616 5.936-12.262.493-.401 1.275-.41 1.787.039.751.648 5.672 11.414 5.933 12.37.306 1.13-.186 2.24-1.083 2.754m-5.768-3.354c-.418-.606-.786-1.203-1.083-1.887-.435-1.018-.72-2.357.198-2.968.585-.392 1.412-.342 1.928.123.651.576.488 1.642.193 2.462-.315.857-.789 1.632-1.236 2.27m2.414-13.26c-1.35-1.34-3.587-1.257-4.852.024-1.145 1.128-6.314 12.29-6.514 13.781-.42 2.857 1.858 5.385 4.591 5.198 1.74-.095 3.166-1.171 4.362-2.438 2.045 2.178 4.67 3.326 7.038 1.692 1.222-.836 1.986-2.29 1.961-3.856-.023-2.071-5.636-13.489-6.586-14.4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirbnbIcon;
