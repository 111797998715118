import React from 'react'

import AboutmeIcon from '../../icons/aboutme'
import AdobeIcon from '../../icons/adobe'
import AmazonIcon from '../../icons/amazon'
import AppleIcon from '../../icons/apple'
import ArchiveorgIcon from '../../icons/archiveorg'
import AirbnbIcon from '../../icons/aribnb'
import AtlassianIcon from '../../icons/atlassian'
import BitmojiIcon from '../../icons/bitmoji'
import BookingIcon from '../../icons/booking'
import BukalapakIcon from '../../icons/bukalapak'
import CodecademyIcon from '../../icons/codecademy'
import DeliverooIcon from '../../icons/deliveroo'
import DiscordIcon from '../../icons/discord'
import DisneyplusIcon from '../../icons/disneyplus'
import DuolingoIcon from '../../icons/duolingo'
import EbayIcon from '../../icons/ebay'
import EnvatoIcon from '../../icons/envato'
import EventbriteIcon from '../../icons/eventbrite'
import EvernoteIcon from '../../icons/evernote'
import FacebookIcon from '../../icons/facebook'
import FirefoxIcon from '../../icons/firefox'
import FlickrIcon from '../../icons/flickr'
import FlipkartIcon from '../../icons/flipkart'
import FoursquareIcon from '../../icons/foursquare'
import FreelancerIcon from '../../icons/freelancer'
import GiphyIcon from '../../icons/giphy'
import GithubIcon from '../../icons/github'
import GoogleIcon from '../../icons/google'
import GravatarIcon from '../../icons/gravatar'
import HubspotIcon from '../../icons/hubspot'
import ImgurIcon from '../../icons/imgur'
import InstagramIcon from '../../icons/instagram'
import KakaoIcon from '../../icons/kakao'
import KomootIcon from '../../icons/komoot'
import LastfmIcon from '../../icons/lastfm'
import LazadaIcon from '../../icons/lazada'
import LinkedinIcon from '../../icons/linkedin'
import MailruIcon from '../../icons/mailru'
import MicrosoftIcon from '../../icons/microsoft'
import MyspaceIcon from '../../icons/myspace'
import NetflixIcon from '../../icons/netflix'
import NikeIcon from '../../icons/nike'
import OkIcon from '../../icons/ok'
import PatreonIcon from '../../icons/patreon'
import PinterestIcon from '../../icons/pinterest'
import PlurkIcon from '../../icons/plurk'
import QuoraIcon from '../../icons/quora'
import QzoneIcon from '../../icons/qzone'
import RamblerIcon from '../../icons/rambler'
import RappiIcon from '../../icons/rappi'
import ReplitIcon from '../../icons/replit'
import SamsungIcon from '../../icons/samsung'
import SkypeIcon from '../../icons/skype'
import SnapchatIcon from '../../icons/snapchat'
import SoundcloudIcon from '../../icons/soundcoud'
import SpotifyIcon from '../../icons/spotify'
import StravaIcon from '../../icons/strava'
import TaringaIcon from '../../icons/taringa'
import TokopediaIcon from '../../icons/tokopedia'
import TreehouseIcon from '../../icons/treehouse'
import TumblrIcon from '../../icons/tumblr'
import TwitterIcon from '../../icons/twitter'
import VenmoIcon from '../../icons/venmo'
import ViemoIcon from '../../icons/vimeo'
import VivinoIcon from '../../icons/vivino'
import VkontakteIcon from '../../icons/vkontakte'
import WattpadIcon from '../../icons/wattpad'
import WeiboIcon from '../../icons/weibo'
import WordpressIcon from '../../icons/wordpress'
import XingIcon from '../../icons/xing'
import YahooIcon from '../../icons/yahoo'
import YandexIcon from '../../icons/yandex'
import ZohoIcon from '../../icons/zoho'

import WhatsappIcon from '../../icons/whatsapp'
import TelegramIcon from '../../icons/telegram'
import ViberIcon from '../../icons/viber'
import ZaloIcon from '../../icons/zalo'
import LineIcon from '../../icons/line'

function EmailResult({ jsonResponse, apiIdLog, requestParams, lang }) {

  return (jsonResponse.number) ?
  (
    <div className="request_report">
        <div className="report_header">
        <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
        <h1 className="report_title">{jsonResponse.number}</h1>
        <div className="report_header_details">
            <div className="report_deliv_container">
            <span className={"report_deliverable_" + (jsonResponse.valid ? "true" : "false")}>{jsonResponse.valid ? (lang == "spanish" ? "Válido" : "Valid") : (lang == "spanish" ? "No válido" : "Not valid")}</span>
            <span className={"report_deliverable_" + (jsonResponse.disposable ? "true" : "false")}>{jsonResponse.disposable ? (lang == "spanish" ? "Desechable" : "Disposable") : (lang == "spanish" ? "No desechable" : "Not disposable")}</span>
            </div>
            <div className="report_score">
            <span className={"report_value " + ( jsonResponse.score >= 8 ? "report_score_red" : jsonResponse.score >= 4 ? "report_score_orange" : "report_score_green")}>{jsonResponse.score}</span>
            <div className="report_score_label">
                <label>Scorce</label>
                <span>{lang == "spanish" ? "Una puntuación de 0-100 que indica la probabilidad de que la dirección de correo electrónico del usuario sea fraudulenta. Un teléfono con una puntuación de 8 o más es riesgoso." : "A score of 0-100 indicating the likelihood that the user's email address is fraudulent. A phone with a score of 8 or above is risky."}</span>
            </div>
            </div>
        </div>
        </div>

        <div className="report_informations">        
        <div className="report_info">
            <label>{lang == "spanish" ? "País" : "Country"}</label>
            <span>{jsonResponse.country || "-"}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Tipo" : "Type"}</label>
            <span>{jsonResponse.type || "-"}</span>
        </div>
        <div className="report_info">
            <label>{lang == "spanish" ? "Operador" : "Carrier"}</label>
            <span>{jsonResponse.carrier || "-"}</span>
        </div>
        </div>


        <div className="report_informations">
        <h2>{lang == "spanish" ? "Detalles de la cuenta" : "Account details"}</h2>

        <div className="social_details">

            {
                [
                    "facebook",
                    "google",
                    "twitter",
                    "instagram",
                    "yahoo",
                    "microsoft",
                    "snapchat",
                    "skype",
                    "whatsapp",
                    "telegram",
                    "viber",
                    "kakao",
                    "ok",
                    "zalo",
                    "line",
                    "flipkart",
                    "bukalapak",
                    "vkontakte",
                    "weibo"
                ]
                .map(social => (
                    <div className="social_detail">
                        <div className="social_img">
                            {
                                social == "facebook" ? <FacebookIcon /> :
                                social == "google" ? <GoogleIcon /> :
                                social == "whatsapp" ? <WhatsappIcon /> :
                                social == "telegram" ? <TelegramIcon /> :
                                social == "viber" ? <ViberIcon /> :
                                social == "skype" ? <SkypeIcon /> :
                                social == "twitter" ? <TwitterIcon /> :
                                social == "instagram" ? <InstagramIcon /> :
                                social == "yahoo" ? <YahooIcon /> :
                                social == "microsoft" ? <MicrosoftIcon /> :
                                social == "snapchat" ? <SnapchatIcon /> :
                                social == "kakao" ? <KakaoIcon /> :
                                social == "ok" ? <OkIcon /> :
                                social == "zalo" ? <ZaloIcon /> :
                                social == "line" ? <LineIcon /> :
                                social == "flipkart" ? <FlipkartIcon /> :
                                social == "bukalapak" ? <BukalapakIcon /> :
                                social == "vkontakte" ? <VkontakteIcon /> :
                                social == "weibo" ? <WeiboIcon /> : <></>
                            }
                        </div>
                        <div className="social_info">
                            <span className="social_name">{social}</span>
                            <span className={"social_value" + (
                                        jsonResponse.account_details[social]?.registered === true ? " social_value_true" :
                                        jsonResponse.account_details[social]?.registered === false ? " social_value_false" :
                                        " social_value_null"
                                    )
                                }>
                                {
                                    jsonResponse.account_details[social]?.registered === true ? (lang == "spanish" ? "Registrado" : "Registred") :
                                    jsonResponse.account_details[social]?.registered === false ? (lang == "spanish" ? "No registrado" : "Not registred") :
                                        (lang == "spanish" ? "Indefinido" : "Undefined")
                                }
                            </span>
                        </div>
                    </div>
                ))
            }

        </div>
        
        </div>

    </div>
  )
  :
  (
    <div className="request_report">
        <div className="report_header">
            <span className="report_id">#{apiIdLog}</span>
            <h1 className="report_title">{requestParams.filter(param => param.name == "phone")[0]?.value}</h1>
            <div className="report_header_details">
                <div className="report_deliv_container">
                    <span className="report_deliverable_false">Error</span>
                </div>
            </div>
        </div>
    </div>
  )

}

export default EmailResult