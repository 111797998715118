import React from "react";

function FoursquareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="-3 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-303 -7559)">
          <g transform="translate(56 160)">
            <path d="M260.98 7399.977l-.688 3.301a.864.864 0 01-.826.665h-5.978c-.668 0-1.219.547-1.219 1.211v.723c0 .664.551 1.211 1.22 1.211h5.053c.473 0 .865.449.767.937-.098.508-.63 3.087-.688 3.36-.04.293-.354.665-.826.665h-4.13c-.864.019-1.041.039-1.553.664-.51.625-5.033 6.25-5.033 6.25-.04.059-.079.04-.079-.02v-19.123c0-.45.373-.821.825-.821h12.408c.432 0 .865.43.747.977"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FoursquareIcon;
