import React from "react";

function WordpressIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-260 -7559)">
          <g transform="translate(56 160)">
            <path d="M222.775 7404.202c.043.319.067.66.067 1.028 0 1.758-.163 1.857-3.815 12.414A9.997 9.997 0 00224 7409a9.947 9.947 0 00-1.225-4.798zm-8.599 5.673l-3.001 8.718c2.025.596 4.171.535 6.146-.159a.897.897 0 01-.071-.138l-3.074-8.421zm6.575-1.38c0-1.236-.444-2.092-.825-2.758-.506-.824-.982-1.521-.982-2.345 0-.919.698-1.775 1.68-1.775.044 0 .086.006.129.008A9.962 9.962 0 00214 7399a9.988 9.988 0 00-8.355 4.507c.637.02 1.492.028 3.309-.114.539-.032.602.76.064.823 0 0-.542.064-1.145.096l3.642 10.832 2.188-6.564-1.558-4.269c-.538-.031-1.048-.095-1.048-.095-.539-.032-.476-.856.063-.824 2.067.159 3.125.171 5.299 0 .539-.032.603.76.064.824 0 0-.543.064-1.145.095l3.614 10.75c1.427-4.768 1.759-5.566 1.759-6.566zM204 7409c0 3.958 2.3 7.379 5.636 9l-4.77-13.07A9.961 9.961 0 00204 7409z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WordpressIcon;
