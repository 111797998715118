import React from "react";

function GoogleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 48 48"
    >
      <g data-name="Layer 2">
        <g fill="none" data-name="invisible box">
          <path d="M0 0H48V48H0z"></path>
          <path d="M0 0H48V48H0z"></path>
        </g>
        <path
          d="M24.7 20.5v7.6h10.9a10.9 10.9 0 01-10.9 8 12.1 12.1 0 117.9-21.3l5.6-5.6A20 20 0 1024.7 44c16.8 0 20.5-15.7 18.9-23.5z"
          data-name="icons Q2"
        ></path>
      </g>
    </svg>
  );
}

export default GoogleIcon;
