import React from "react";

function StravaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M20.516 23.927l-2.786-5.49h-4.083L20.517 32l6.865-13.563h-4.083zm-6.563-12.953l3.781 7.464h5.563L13.953 0 4.62 18.438h5.557z"></path>
    </svg>
  );
}

export default StravaIcon;
