import React from "react";

function BookingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 192 192"
    >
      <defs>
        <linearGradient
          id="a"
          x1="16"
          x2="176"
          y1="96"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
        </linearGradient>
      </defs>
      <path
        strokeLinejoin="round"
        strokeWidth="1"
        d="M75.984 145.016H50.841V115.31c0-6.33 2.465-9.74 7.986-10.422h17.256a18.873 18.873 0 0114.81 5.373 18.378 18.378 0 015.403 14.643c0 12.662-7.74 20.21-20.213 20.21zM50.791 64.71v-7.792c0-6.818 2.958-10.13 9.368-10.52h12.916c11.044 0 17.749 6.527 17.749 17.533 0 8.279-4.536 18.019-17.256 18.019H50.89zm57.387 29.707l-4.536-2.532 3.944-3.41c4.634-3.895 12.325-12.759 12.325-28.002C119.91 37.097 101.57 22 73.223 22h-35.99C28.79 22.286 22.075 29.093 22 37.438v132.464h51.864c31.552 0 51.865-16.947 51.865-43.246 0-14.122-6.557-26.297-17.65-32.385"
        stroke="#002E71"
      ></path>
      <ellipse
        cx="151.94"
        cy="152.17"
        fill="#002E71"
        stroke="#002E71"
        strokeLinejoin="round"
        strokeWidth="8"
        rx="11.805"
        ry="11.707"
      ></ellipse>
    </svg>
  );
}

export default BookingIcon;
