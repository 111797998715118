import React from 'react'
import TrueIcon from '../../icons/true'
import FalseIcon from '../../icons/false'

function IneResult({ jsonResponse, apiIdLog, requestParams, lang, setSwitchLayout }) {

  return (jsonResponse.estatus == "OK") ?
  (
    <div className="request_report">
        <div className="report_header">
        <span className="report_id" onClick={() => console.log(jsonResponse)}>#{apiIdLog}</span>
        <div className="report_params">
            <div className="report_param">
                <div className="report_label">cic</div>
                <h1 className="report_title">{requestParams.filter(param => param.name == "cic")[0]?.value || "-"}</h1>
            </div>
            <div className="report_param">
                <div className="report_label">identificadorCiudadano</div>
                <h1 className="report_title">{requestParams.filter(param => param.name == "identificadorCiudadano")[0]?.value || "-"}</h1>
            </div>
        </div>
        </div>

        <div className="report_informations">
            <span className="report_subtitle">{lang == "spanish" ? "Mensaje" : "Message"}</span>
            <h2>{jsonResponse.mensaje}</h2>
            <p>{jsonResponse.informacionAdicional}</p>
        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Resultado de la Validación" : "Validation Result"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Clave del Mensaje" : "Message Key"}</label>
                <span>{jsonResponse.claveMensaje}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Código de Validación" : "Validation Code"}</label>
                <span>{jsonResponse.codigoValidacion}</span>
            </div>
            
            <div className="report_info">
                <label>{lang == "spanish" ? "Estatus" : "Status"}</label>
                <span>{jsonResponse.estatus}</span>
            </div>

        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Identificación del Elector" : "Voter Identification"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Clave del Elector" : "Voter Key"}</label>
                <span>{jsonResponse.claveElector}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Número OCR" : "OCR Number"}</label>
                <span>{jsonResponse.ocr}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Número CIC" : "CIC Number"}</label>
                <span>{jsonResponse.cic}</span>
            </div>

        </div>

        <div className="report_informations">
            <h2>{lang == "spanish" ? "Detalles de Emisión" : "Issuance Details"}</h2>

            <div className="report_info">
                <label>{lang == "spanish" ? "Número de Emisión" : "Issue Number"}</label>
                <span>{jsonResponse.numeroEmision}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Año de Registro" : "Registration Year"}</label>
                <span>{jsonResponse.anioRegistro}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Año de Emisión" : "Year of Issue"}</label>
                <span>{jsonResponse.anioEmision}</span>
            </div>

            <div className="report_info">
                <label>{lang == "spanish" ? "Vigencia" : "Validity"}</label>
                <span>{jsonResponse.vigencia}</span>
            </div>

        </div>




    </div>
  )
  :
  (
    <div className="request_report">
        <div className="report_header">
            <span className="report_id">#{apiIdLog}</span>

            <div className="report_params">
                <div className="report_param">
                    <div className="report_label">cic</div>
                    <h1 className="report_title">{requestParams.filter(param => param.name == "cic")[0]?.value || "-"}</h1>
                </div>
                <div className="report_param">
                    <div className="report_label">identificadorCiudadano</div>
                    <h1 className="report_title">{requestParams.filter(param => param.name == "identificadorCiudadano")[0]?.value || "-"}</h1>
                </div>
            </div>


            <div className="report_header_details">
                <div className="report_deliv_container">
                    <span className="report_deliverable_false">Error</span>
                    <span className="report_error_result" onClick={() => setSwitchLayout("table")}>{lang == "spanish" ? "Ver más detalles" : "See more details" }</span>
                </div>
            </div>
        </div>
    </div>
  )

}

export default IneResult