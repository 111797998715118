import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import { toPng } from 'html-to-image';

import ReactGA from "react-ga";

import LogoWhite from "../../media/logo_white.svg";

function ApiLog(props) {

  let { endPointId } = useParams();

  const [logsLoading, setLogsLoading] = useState(true);
  const [logsList, setLogsList] = useState([]);
  const [offset, setOffset] = useState(0); // Initialize offset state
  const [hasMoreLogs, setHasMoreLogs] = useState(true); // To track if more logs are available
  const [isButtonLoading, setIsButtonLoading] = useState(false); // Track button loading status
  const [tableHead, setTableHead] = useState({
    "a6cc88ec-6a7a-4b98-9274-f9645a50d062": ["email"],
    "9dc41379-1865-4415-9eb0-a9a5bb454368": ["ip"],
    "4b5cd30a-2f15-481f-91e6-53f9a8ce2bb0": ["curp"],
    "1f581de8-53db-4f27-80ed-3a3afd327f9c": ["phone"],
    "05c6fc42-c8c6-462d-9448-118800749b92": ["cic", "identificadorCiudadano", "numeroEmision", "claveElector", "ocr"]
  });
  const endpointType = {
    "a6cc88ec-6a7a-4b98-9274-f9645a50d062": "email",
    "9dc41379-1865-4415-9eb0-a9a5bb454368": "ip",
    "4b5cd30a-2f15-481f-91e6-53f9a8ce2bb0": "curp",
    "1f581de8-53db-4f27-80ed-3a3afd327f9c": "phone",
    "05c6fc42-c8c6-462d-9448-118800749b92": "ine"
  }
  const [errorLogs, setErrorLogs] = useState("loading");
  const [logsJsonList, setLogsJsonList] = useState("[]");
  const tableResult = useRef(null);

  const [filterRequest, setFilterRequest] = useState("all");

  const [usernameOpen, setUsernameOpen] = useState(false);

  const [duplicateLogs, setDuplicateLogs] = useState([]);

  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Extract each part of the date
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    // Format the date and time in DD/MM/YYYY HH:MM:SS format
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }


  const delay = ms => new Promise(res => setTimeout(res, ms));

  const tableToPng = useCallback(async (i) => {
    setLogsJsonList(logsList[i].JsonResponse.replaceAll(":null", ':"null"').replaceAll(":true", ':"true"').replaceAll(":false", ':"false"'));
    await delay(500);
    if (tableResult.current === null) {
      return
    }
    toPng(tableResult.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'result.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [tableResult, logsList]);

  const hideUsernameDropdown = (e) => {
    if (!(e.target.closest('.username'))) {
      if (!(e.target.closest('.username_dropdown'))) {
        setUsernameOpen(false)
      }
    }
  }

  const fetchLogs = async (newOffset = 0) => {
    try {
      const endPointIdBody = { endpointId: endPointId, offset: newOffset };
      const res = await axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/endpoint-logs/neo`,
        endPointIdBody
      );

      if (res.data.err) {
        setErrorLogs(res.data.err);
        setLogsLoading(false);
      } else if (res.data) {
        const seen = new Map();
        const logsListOriginal = res.data.logs.map(log => {
          let newJsonRequest = {};
          if (log.JsonRequest) {
            JSON.parse(log.JsonRequest).forEach(param => {
              if (param.name && tableHead[endPointId].includes(param.name)) {
                newJsonRequest[param.name] = param.value;
              }
            });
            log.JsonRequest = newJsonRequest;
          }
          return log;
        });

        logsListOriginal.forEach(item => {
          const jsonString = JSON.stringify(item.JsonRequest);
          if (seen.has(jsonString)) {
            const entry = seen.get(jsonString);
            entry.instances.push(item);
            entry.dates.push(item.CreationDate);
          } else {
            item.Duplicate = 1;
            seen.set(jsonString, { instances: [item], dates: [item.CreationDate] });
          }
        });

        const logsListUpdated = Array.from(seen.values()).map(entry => {
          const instances = entry.instances;
          instances.sort((a, b) => new Date(b.CreationDate) - new Date(a.CreationDate)); // Sort by CreationDate descending

          const mostRecentWithEmptyErrorSource = instances.find(item => item.ErrorSource === "");
          const chosenItem = mostRecentWithEmptyErrorSource || instances[0];

          chosenItem.Duplicate = instances.length;
          chosenItem.Replicate = instances;
          return chosenItem;
        });

        setLogsList(prevLogs => [...prevLogs, ...logsListUpdated]); // Append new logs to the list
        setLogsLoading(false);
        setIsButtonLoading(false); // Stop button loading animation
        setErrorLogs("");

        if (res.data.logs.length === 0) {
          setHasMoreLogs(false); // No more logs available
        }
      }
    } catch (error) {
      setErrorLogs("An error occurred, please try again later");
      setLogsLoading(false);
      setIsButtonLoading(false); // Stop button loading animation
    }
  };


  const loadMoreLogs = async () => {
    setIsButtonLoading(true); // Start button loading animation
    const newOffset = offset + 100; // Increase offset by 100
    setOffset(newOffset); // Update offset state
    fetchLogs(newOffset); // Fetch logs with the new offset
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    fetchLogs(offset); // Fetch initial logs
  }, []);

  return (
    <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
      <div className="navbar_blue">
        <Link to="/"><img className="logo" src={LogoWhite} /></Link>
        <Link to={"/api-request/" + endPointId} className="btn_white btn_navbar">
          <svg fill="#2139da" width="28px" height="28px">
            <g>
              <g>
                <path class="st0" d="M18.7,17.7h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c1.1,0,2-0.9,2-2v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6
                        C22.7,15.9,20.9,17.7,18.7,17.7z"/>
              </g>
              <g>
                <path class="st0" d="M15.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3
                        l3.3,3.3c0.4,0.4,0.4,1,0,1.4C16.2,21.6,16,21.7,15.7,21.7z M10.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4
                        c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C11.2,21.6,11,21.7,10.7,21.7z"/>
              </g>
            </g>
          </svg>
          {props.lang == "spanish" ? "Regresar" : "Return"}
        </Link>

        <div className="btn_blue_check_container">
          <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
          <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
        </div>

        <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
          <span>{props.user.username}</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none" /><path d="M7 10l5 5 5-5z" /></svg>
        </div>
        {
          usernameOpen &&
          <div className="username_dropdown">
            <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
            <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
            <div className="username_info">
              <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
              <p><span>{props.lang == "spanish" ? "Tipo " : "Type "}:</span> {props.user.type}</p>
              {props.user.type != "user" && <Link to="/users"><span>{props.lang == "spanish" ? "Gestión de usuarios" : "User management"}</span></Link>}
              {props.user.type != "user" && <Link to="/notifications"><span>{props.lang == "spanish" ? "Notificación por email" : "Email notfication"}</span></Link>}
            </div>
            {/* <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div> */}
            <div className="btn_username_container">
              <div className="btn_blue_check_container">
                <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
              </div>
              <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
            </div>
          </div>
        }
      </div>
      <div className="content content_table">
        <div className="table_log_title">
          <h2>{props.lang == "spanish" ? "Historial de consultas" : "Query history"}</h2>
          <Link to={"/api-request/" + endPointId} className="btn_blue btn_outline">{props.lang == "spanish" ? "Enviar otra solicitud" : "Send another request"}</Link>
        </div>
        {(!logsLoading || errorLogs == "") &&
          <>
            <div className="content_table_title_flex">
              <div className="content_table_switch">
                <span onClick={() => setFilterRequest("all")} className={filterRequest == "all" ? "active" : ""}>{props.lang == "spanish" ? "Todo" : "All"}</span>
                <span onClick={() => setFilterRequest("success")} className={filterRequest == "success" ? "active" : ""}>{props.lang == "spanish" ? "Éxito" : "Success"}</span>
                <span onClick={() => setFilterRequest("fail")} className={filterRequest == "fail" ? "active" : ""}>{props.lang == "spanish" ? "Fallo" : "Fail"}</span>
              </div>
            </div>
            <div className="table_log">
              <table>
                <thead>
                  <tr>
                    <td>{props.lang == "spanish" ? "Fecha" : "Date"}</td>
                    {
                      // when its not the endpoint equal to
                      tableHead[endPointId].map((param, paramIndex) => {
                        return <td key={paramIndex} className="param">{param}</td>;
                      }
                      )
                    }
                    {/* {
                    endPointId != "df507b3d-f97e-4f06-8f5d-88c08ee085ed"
                    ? <td>{props.lang == "spanish" ? "Archivo" : "Archive"}</td>
                    : <></>
                  } */}
                    <td>Logs</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    logsList
                      .filter(log => log.ClientId == "8d2f9f75-bcf6-41ef-97c9-5c59fe568a2c")
                      .filter(log => filterRequest == "success" ? log.ErrorSource == '' : filterRequest == "fail" ? log.ErrorSource != '' : true)
                      .map((log, LogIndex) =>
                        <tr key={log.ApiLogId}>
                          <td><span>{formatDate(log.CreationDate)}</span></td>
                          {
                            // when its not the endpoint equal to
                            tableHead[endPointId].map((param, paramIndex) =>
                              <td key={LogIndex + "" + paramIndex}>
                                <span>{log.JsonRequest[param] || ""}</span>
                                </td>
                            )
                          }
                          {/* {
                      endPointId != "df507b3d-f97e-4f06-8f5d-88c08ee085ed"
                      ? <td><div className="btn_blue" onClick={() => tableToPng(LogIndex)}>{props.lang == "spanish" ? "Descargar PNG" : "Download PNG"}</div></td>
                      : <></>
                    } */}
                          <td><Link to={"/api-result/" + log.ApiLogId + "/" + endpointType[endPointId]}>Logs</Link></td>
                        </tr>
                      )
                  }
                </tbody>
              </table>
            </div>
          </>
        }
      </div>
      <div className="table_logs">
        <div className="table_request" ref={tableResult}>
          {logsList.length > 0 && <JsonToTable json={JSON.parse(logsJsonList)} />}
        </div>
      </div>

      {hasMoreLogs && !logsLoading && (
        <div className="btn_loading_container">
          <div
            onClick={() => loadMoreLogs()}
            className={`btn_loading_blue ${isButtonLoading ? "btn_loading_blue_loading" : ""}`}
          >
            {props.lang == "spanish" ? "Ver más" : "View More"}
          </div>
        </div>
      )}

    </div>
  );

}

export default ApiLog;