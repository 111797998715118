import React from "react";

function FirefoxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 -0.5 25 25"
    >
      <path d="M12.474 24l-.182.001c-2.534 0-4.886-.778-6.83-2.108l.042.027a12.68 12.68 0 01-4.521-5.425l-.032-.081a11.502 11.502 0 01-.925-4.139l-.001-.022a17.382 17.382 0 01.381-4.707l-.021.115a17.65 17.65 0 011.58-4.41l-.046.099A11.506 11.506 0 014.386.005l.006-.006-.152 3.882q.152-.194.939-.214t.967.214a4.65 4.65 0 012.187-1.895l.031-.011a7.624 7.624 0 013.228-.815h.012a9.01 9.01 0 00-1.629 2.011l-.023.041a3.823 3.823 0 00-.808 2.254v.005c.243.077.535.142.834.183l.029.003q.518.076.87.104t.939.055.698.042q.207.069.131.629a2.308 2.308 0 01-.426 1.046l.004-.006a2.251 2.251 0 01-.227.255l-.001.001a3.937 3.937 0 01-.757.48l-.024.01a4.616 4.616 0 01-1.367.466l-.028.004.212 2.616-1.92-.926a1.725 1.725 0 00-.102 1.139l-.003-.012c.096.357.268.666.499.921l-.002-.002c.242.26.546.457.89.569l.015.004a2.277 2.277 0 001.134.088l-.014.002c.509-.093.963-.26 1.377-.492l-.022.012q.65-.352 1.154-.622a1.89 1.89 0 011.019-.24h-.004.021c.467 0 .893.173 1.218.458l-.002-.002a.9.9 0 01.269.904l.001-.006q-.014.028-.034.076a1.023 1.023 0 01-.119.174l.001-.001a.87.87 0 01-.245.212l-.004.002a1.388 1.388 0 01-.427.144l-.008.001a2.072 2.072 0 01-.651.013l.011.001a4.905 4.905 0 01-1.973 1.859l-.027.013a5.432 5.432 0 01-2.189.452c-.249 0-.493-.016-.733-.048l.028.003a5.543 5.543 0 002.207 1.132l.038.008a5.486 5.486 0 002.362.075l-.034.005a8.518 8.518 0 002.183-.742l-.049.022a7.07 7.07 0 001.773-1.212l-.004.003a5.456 5.456 0 001.098-1.411l.014-.029a5.531 5.531 0 00.539-2.672v.012a8.08 8.08 0 00-.537-2.66l.019.055a4.221 4.221 0 00-1.086-1.719l-.002-.001c.723.3 1.347.669 1.91 1.111l-.017-.013c.475.41.84.933 1.056 1.529l.008.025A10.383 10.383 0 0016.992.922l-.016-.013a10.4 10.4 0 015.674 3.834l.02.027a12.038 12.038 0 012.086 7.168v-.017l.001.12c0 1.217-.205 2.386-.583 3.474l.022-.074a11.75 11.75 0 01-1.729 3.32l.022-.031a14.386 14.386 0 01-2.583 2.685l-.029.022a11.975 11.975 0 01-7.405 2.56h-.001z"></path>
    </svg>
  );
}

export default FirefoxIcon;
