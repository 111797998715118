import React from "react";

function BukalapakIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 50.8 50.8"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#002E71"
        strokeWidth="3.175"
        d="M14.651 5.96L8.212 8.08a2.19 2.19 0 00-.871.482c-.218.192-.412.481-.557.795a2.216 2.216 0 00-.218.939c0 .313.073.626.218.963l8.327 18.908c.242.553.678.987 1.21 1.204.533.24 1.138.265 1.72.072l2.638-.915a2.392 2.392 0 001.307-1.06 2.462 2.462 0 00.242-1.662L17.701 7.671c-.072-.338-.193-.65-.387-.892a2.189 2.189 0 00-.75-.65 2.162 2.162 0 00-.92-.289c-.315-.048-.654 0-.993.12zm10.845 31.264a4.735 4.735 0 00-3.244-1.301 4.66 4.66 0 00-3.195 1.349 4.58 4.58 0 00-1.307 3.227c0 1.229.532 2.36 1.355 3.18.823.818 1.961 1.3 3.22 1.3 1.259 0 2.396-.53 3.22-1.349a4.584 4.584 0 001.307-3.203 4.626 4.626 0 00-1.356-3.203zm8.303-4.842c-.073.024-.363.169-.75.314-.315.144-.702.289-1.041.433a1.873 1.873 0 00-1.065 1.036 2.123 2.123 0 000 1.59l1.137 2.914c.121.265.267.506.46.698.194.193.412.337.678.458.266.12.508.144.75.12.267 0 .557-.072.872-.192 3.074-1.132 5.592-3.06 7.238-5.516 1.573-2.36 2.372-5.25 2.106-8.43-.29-3.276-1.622-6.142-3.631-8.31a13.877 13.877 0 00-7.989-4.287 17.005 17.005 0 00-3.074-.192c-.968.048-1.96.168-3.001.36a4.01 4.01 0 00-.775.242.915.915 0 00-.194.12c-.072.12-.12.217-.145.337 0 .097 0 .217.024.337l1.525 5.637c.049.168.17.337.315.433.145.072.315.12.484.072a11 11 0 011.864-.289c.557-.024 1.065-.024 1.477.024 1.646.12 3.001.82 3.994 1.83 1.09 1.109 1.767 2.578 1.888 4.071.145 1.421-.049 2.578-.581 3.59-.533 1.01-1.356 1.854-2.542 2.576z"
      ></path>
    </svg>
  );
}

export default BukalapakIcon;
