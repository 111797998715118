import React from "react";

function PatreonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 32 32"
    >
      <path d="M20.23 1.604h-.027c-5.961 0-10.793 4.832-10.793 10.793S14.242 23.19 20.203 23.19c5.955 0 10.783-4.822 10.793-10.775v-.001C30.992 6.461 26.18 1.633 20.233 1.605h-.003zm-19.226 0v28.792h5.274V1.604z"></path>
    </svg>
  );
}

export default PatreonIcon;
