import React from "react";

function ZohoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 1024 1024"
    >
      <g>
        <circle cx="512" cy="512" r="512" fill="#002E71"></circle>
        <g>
          <path
            fill="#fff"
            d="M810.3 452.6L829 431v147.8l-18.7 18.7V452.6zm-137.7-2.4h135.2l19-21.9-134.2.1m45.2 62.6c-9.3 0-16.4 3.2-21.7 9.6-5.3 6.4-7.9 15.2-7.9 26.6 0 11.2 2.7 19.9 8 26.2 5.3 6.3 12.4 9.5 21.6 9.5 9.2 0 16.3-3.2 21.5-9.5 5.3-6.4 7.9-15.1 7.9-26.2 0-11.4-2.7-20.2-7.9-26.6-5.2-6.4-12.3-9.6-21.5-9.6zm69.1-37.4v145.5H671.1V453.6h135.8zm-15.2 73.6c0-8-1.4-15.5-4.1-22.3-2.7-6.8-6.7-13-12.1-18.4-5.1-5.2-10.8-9.1-17.2-11.8-6.3-2.6-13.2-3.9-20.5-3.9-7.4 0-14.3 1.3-20.7 3.9-6.4 2.6-12.1 6.6-17.2 11.8-5.3 5.4-9.3 11.5-12 18.3-2.7 6.8-4 14.3-4 22.4 0 8 1.3 15.4 4 22.3s6.7 13.1 12 18.5c4.9 5.1 10.6 9 17 11.6 6.4 2.6 13.3 3.9 20.9 3.9 7.3 0 14.1-1.3 20.5-3.9 6.4-2.6 12.1-6.5 17.2-11.6 5.3-5.5 9.4-11.6 12.1-18.5 2.7-6.8 4.1-14.3 4.1-22.3z"
            className="st1"
          ></path>
          <path
            fill="#fff"
            d="M675.2 552l-9 25.1L648 448.7l10.1-28.9m-133.4 45.3l-.2-1.7L645 447l10.7-30.6-133.4 18.5-9.5 31.8 11.9-1.6zm138.6 115.6l-131.7 18.5-17.9-126 13.4 6 .7-1.5-.5-2.1-14.1-6.4-.4-2.5 11.8-1.6.4 1.7 119.7-16.3 18.6 130.2zm-26.4-14.4c0-1-.1-2.1-.3-3.3l-13.1-89.3c-.6-4.1-2-7.2-4.3-9.3-1.8-1.6-4.1-2.5-6.7-2.5-.7 0-1.3.1-2 .2-3.5.5-6.1 2.1-7.7 4.7-1.2 1.9-1.8 4.2-1.8 6.9 0 1 .1 2.1.3 3.2l4.9 34.3-38.3 5.6-4.9-34.3c-.6-4-2-7.1-4.2-9.2-1.8-1.7-4.1-2.6-6.6-2.6-.6 0-1.2 0-1.8.1-3.6.5-6.4 2.1-8 4.7-1.2 1.9-1.8 4.2-1.8 6.9 0 1 .1 2.1.3 3.3L554 575c.6 4.1 2 7.2 4.4 9.2 1.8 1.6 4.1 2.4 6.8 2.4.7 0 1.5-.1 2.2-.2 3.3-.5 5.9-2.1 7.4-4.7 1.1-1.9 1.6-4.1 1.6-6.7 0-1-.1-2.1-.3-3.3l-5.4-35.3 38.3-5.6 5.4 35.3c.6 4.1 2 7.2 4.3 9.2 1.8 1.6 4.1 2.4 6.7 2.4.7 0 1.3-.1 2.1-.2 3.4-.5 6.1-2.1 7.7-4.7 1.2-1.6 1.7-3.9 1.7-6.5z"
            className="st1"
          ></path>
          <path
            fill="#fff"
            d="M353.6 543l-6.2-47 47.2-111.9 11.7 39.4L357.6 534l-4 9zm101.9-65.8c-3.9-1.7-7.7-2.5-11.4-2.5-4.2 0-8.3 1.1-12.2 3.2-7.4 4.1-13.4 11.5-18 22.4-3 7.1-4.5 13.6-4.5 19.6 0 3 .4 5.9 1.1 8.6 2.3 8.1 7.5 13.8 15.9 17.4 3.9 1.6 7.6 2.5 11.3 2.5 4.2 0 8.2-1.1 12.2-3.2 7.4-4 13.3-11.3 17.8-22 3.1-7.3 4.6-14 4.6-20.1 0-2.9-.4-5.7-1.1-8.4-2.3-8.2-7.4-13.9-15.7-17.5zm62.2-39.6l-120.1-55 11.8 39.8 117.8 53.2m-.1 3.6l-53.3 121.1L355.6 547v-.1l53.3-121 118.2 53.3zm-31.9 30.6v-2.2c0-6.8-1.2-13.5-3.6-20-2.5-6.9-6.2-12.9-10.9-17.8-4.7-5-10.4-8.9-17.1-11.7-6.7-2.8-13.4-4.2-20.2-4.2h-.3c-6.9 0-13.7 1.6-20.4 4.6-7 3.1-13.1 7.3-18.3 12.8-5.2 5.5-9.4 12.1-12.7 19.8-3.2 7.6-5 15.2-5.3 22.8 0 .9-.1 1.7-.1 2.6 0 6.7 1.2 13.3 3.4 19.7 2.4 6.8 6 12.6 10.8 17.6 4.7 4.9 10.6 8.9 17.5 11.8 6.6 2.8 13.3 4.2 20.1 4.2h.2c6.8 0 13.6-1.5 20.3-4.4 7.1-3.2 13.2-7.5 18.5-13 5.2-5.5 9.5-12.1 12.7-19.7 3.3-7.8 5.1-15.4 5.4-22.9z"
            className="st1"
          ></path>
          <path
            fill="#fff"
            d="M349.5 573.2l-19-124.8 8.1-42.3 18.8 126-7.9 41.1zm-2.6 5.2l-130.7 20.5-21-128.4 132.2-20.2 19.5 128.1zm-30.1-14.1c0-.7-.1-1.4-.2-2.2-.3-1.8-1-3.4-2-4.6-1-1.2-2.4-2.2-4.2-2.8-1.8-.6-3.9-.9-6.4-.9-2.4 0-5.1.3-8.1.8l-34.9 6c.4-2.7 1.7-6.2 4.1-10.5 2.6-4.8 6.5-10.6 11.6-17.4 1.8-2.4 3.1-4.1 4-5.4.7-.8 1.6-2 2.8-3.6 8-10.5 12.9-19 14.8-25.7 1.1-3.8 1.7-7.6 2-11.5.1-1.1.1-2.1.1-3.1 0-2.8-.2-5.5-.7-8.2-.4-2.4-1-4.5-1.8-6.1-.8-1.6-1.8-2.9-3.1-3.6-1.4-.9-3.4-1.2-6-1.2-2.2 0-4.8.3-7.8.8l-40.4 6.9c-4.9.8-8.5 2.3-10.8 4.4-1.8 1.7-2.8 3.9-2.8 6.5 0 .7.1 1.4.2 2.1.6 3.4 2.4 5.9 5.2 7.2 1.7.8 3.8 1.1 6.2 1.1 1.6 0 3.4-.2 5.4-.5l33.8-5.7c.1.6.1 1.2.1 1.8 0 2.1-.4 4.2-1.1 6.2-1 2.8-3.5 6.8-7.5 11.9-1.1 1.4-2.9 3.5-5.2 6.5-9.1 11-15.6 20.4-19.6 28.3-2.8 5.4-4.7 10.7-5.8 15.9-.6 3-.9 6-.9 8.8 0 2 .2 4 .5 5.8.5 2.7 1.1 4.9 2 6.7.9 1.8 2 3.1 3.5 3.9 1.3.7 3.3 1 5.9 1 3.4 0 7.9-.5 13.7-1.5l36.6-6.3c6.5-1.1 11-2.7 13.6-4.8 2.2-1.9 3.3-4.3 3.2-7zM204.3 422.5l-6.7 33.3-2.3 11.3 132.1-20.2 8.7-45.5-131.8 21.1z"
            className="st1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ZohoIcon;
