import React from "react";

function SnapchatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 -0.5 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#002E71" transform="translate(-380 -7560)">
          <g transform="translate(56 160)">
            <path d="M334.126 7419c-1.287 0-1.848-.29-2.942-1.073-.99-.708-1.625-1.097-3.463-.734-.717.141-.703.077-.857-.64-.082-.377-.141-.607-.281-.629-1.493-.233-2.376-.577-2.552-.993-.084-.2.016-.358.17-.384 1.947-.324 3.52-1.958 4.204-3.568l.005-.011c.173-.354.207-.66.103-.909-.235-.56-.992-.678-1.535-.896-.374-.15-.99-.466-.907-.9.075-.4.714-.652 1.056-.49.859.407 1.547.283 1.753.09-.01-.195.12-.406.12-.605 0-1.379-.348-3.095.086-4.078 1.873-4.25 8.12-4.229 9.984-.002.434.984.327 2.701.24 4.082l-.003.066a46 46 0 00-.032.537c.04.037.2.166.53.18.279-.012.6-.103.955-.27.417-.198 1.168.105 1.176.558.004.211-.151.527-.914.832-.541.216-1.3.336-1.534.896-.105.25-.071.555.102.908l.005.012c.053.126 1.338 3.09 4.204 3.568.12.02.208.13.2.253a.395.395 0 01-.031.132c-.175.414-1.057.757-2.551.99-.14.022-.2.252-.28.627-.123.571-.104.767-.858.618-1.818-.359-2.507.074-3.462.757-.704.504-1.5 1.076-2.691 1.076"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SnapchatIcon;
