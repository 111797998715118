import React from "react";

function LastfmIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="#002E71"
      version="1.1"
      viewBox="-271 324 256 154"
      xmlSpace="preserve"
    >
      <path d="M-54.3 387.3c-2.2-.7-4.4-1.4-6.5-2.1-15.9-5-25.5-8-25.5-20.4 0-10.1 7.8-17.4 18.5-17.4 8.2 0 14.3 3.4 19.8 11.1.5.7 1.5 1 2.3.6l15.9-8.3c.4-.2.8-.6.9-1.1s.1-1-.2-1.4c-8.6-15.3-21-22.7-38-22.7-25.8 0-42.4 15.6-42.4 39.7 0 24.7 16.1 34.7 45.8 44.4 17.2 5.7 24.8 8.8 24.8 21 0 13.8-12.4 23.7-29.4 23.1-17.8-.6-23.2-10-29.9-25.4-11.5-26.1-24.5-56.6-24.6-56.9-13.1-30.2-39-47.5-71.2-47.5-42.5 0-77 34.5-77 77s34.5 77 77 77c23.2 0 44.9-10.3 59.5-28.2.4-.5.5-1.2.3-1.8l-9.7-22.4c-.3-.6-.9-1-1.6-1.1-.7 0-1.3.4-1.7 1-9.2 17.5-27.1 28.4-46.9 28.4-29.1 0-52.8-23.7-52.8-52.9 0-29.1 23.7-52.9 52.8-52.9 21.2 0 40.6 12.6 48.4 31.4l24 54.8 2.8 6.2c10.9 25.3 26.8 36.6 51.8 36.7 29.7 0 52.1-19.7 52.1-45.8.2-26.1-14.3-36-39.3-44.1z"></path>
    </svg>
  );
}

export default LastfmIcon;
